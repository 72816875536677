@import url(@fortawesome/fontawesome-free/css/all.css);
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:400,600|Lato:400,300");

/*
    Optics by Pixelarity
    pixelarity.com | hello@pixelarity.com
    License: pixelarity.com/license
*/

html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    -webkit-text-size-adjust: none;
}

mark {
    background-color: transparent;
    color: inherit;
}

input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input, select, textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
}

/* Basic */

@-ms-viewport {
    width: device-width;
}

body {
    -ms-overflow-style: scrollbar;
}

@media screen and (max-width: 480px) {
    html, body {
        min-width: 320px;
    }
}

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body.is-preload *, body.is-preload *:before, body.is-preload *:after {
    -moz-animation: none !important;
    -webkit-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
    -moz-transition: none !important;
    -webkit-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
}

/* Type */
html, body {
    background: #333;
}

#page-wrapper {
    background: #fff;
}

body {
    color: #555;
}

body, input, select, textarea {
    font-family: Helvetica, Arial, Sans-serif;
    font-size: 13pt;
    font-weight: 300;
    line-height: 1.9em;
}

@media screen and (max-width: 1680px) {
    body, input, select, textarea {
        font-size: 12pt;
    }
}

@media screen and (max-width: 1280px) {
    body, input, select, textarea {
        font-size: 11pt;
    }
}

@media screen and (max-width: 980px) {

    body, input, select, textarea {
        font-size: 12pt;
    }

}

@media screen and (max-width: 736px) {

    body, input, select, textarea {
        font-size: 12pt;
    }

}

@media screen and (max-width: 480px) {

    body, input, select, textarea {
        font-size: 12pt;
    }

}

@media screen and (max-width: 360px) {

    body, input, select, textarea {
        font-size: 11pt;
    }

}

a {
    text-decoration: underline;
}

a:hover {
    text-decoration: none;
}

strong, b {
    font-weight: 400;
}

em, i {
    font-style: italic;
}

p {
    margin: 0 0 2em 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Josefin Sans", Arial, Helvetica, sans-serif;
    letter-spacing: 0.1em;
    line-height: 1em;
    margin: 0 0 1em 0;
    text-transform: uppercase;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: inherit;
    text-decoration: none;
}

h2 {
    font-size: 1.75em;
    line-height: 1.5em;
}

h3 {
    font-size: 1.35em;
    line-height: 1.5em;
}

h4 {
    font-size: 1.1em;
    line-height: 1.5em;
}

h5 {
    font-size: 0.9em;
    line-height: 1.5em;
}

h6 {
    font-size: 0.7em;
    line-height: 1.5em;
}

@media screen and (max-width: 736px) {

    h2 {
        font-size: 1.5em;
    }

    h3 {
        font-size: 1.25em;
    }

    h4 {
        font-size: 1em;
    }

}

sub {
    font-size: 0.8em;
    position: relative;
    top: 0.5em;
}

sup {
    font-size: 0.8em;
    position: relative;
    top: -0.5em;
}

blockquote {
    border-left-style: solid;
    border-left-width: 4px;
    font-style: italic;
    margin: 0 0 2em 0;
    padding: 0.5em 0 0.5em 2em;
}

code {
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    font-family: "Courier New", monospace;
    font-size: 0.9em;
    margin: 0 0.25em;
    padding: 0.25em 0.65em;
}

pre {
    -webkit-overflow-scrolling: touch;
    font-family: "Courier New", monospace;
    font-size: 0.9em;
    margin: 0 0 2em 0;
}

pre code {
    display: block;
    line-height: 1.75em;
    padding: 1em 1.5em;
    overflow-x: auto;
}

hr {
    border: 0;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    margin: 3em 0;
}

hr.major {
    margin: 4em 0;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.inline-block {
  display: inline-block;
}

input, select, textarea {
    color: #555;
}

a {
    color: #65bfad;
}

strong, b {
    color: #555;
}

h1, h2, h3, h4, h5, h6 {
    color: #555;
}

blockquote {
    border-left-color: rgba(144, 144, 144, 0.25);
}

code {
    background: rgba(144, 144, 144, 0.075);
    border-color: rgba(144, 144, 144, 0.25);
}

hr {
    border-bottom-color: rgba(144, 144, 144, 0.25);
}

/* Row */

.row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
}

.row > * {
    box-sizing: border-box;
}

.row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
}

.row.aln-left {
    justify-content: flex-start;
}

.row.aln-center {
    justify-content: center;
}

.row.aln-right {
    justify-content: flex-end;
}

.row.aln-top {
    align-items: flex-start;
}

.row.aln-middle {
    align-items: center;
}

.row.aln-bottom {
    align-items: flex-end;
}

.row > .imp {
    order: -1;
}

.row > .col-1 {
    width: 8.33333%;
}

.row > .off-1 {
    margin-left: 8.33333%;
}

.row > .col-2 {
    width: 16.66667%;
}

.row > .off-2 {
    margin-left: 16.66667%;
}

.row > .col-3 {
    width: 25%;
}

.row > .off-3 {
    margin-left: 25%;
}

.row > .col-4 {
    width: 33.33333%;
}

.row > .off-4 {
    margin-left: 33.33333%;
}

.row > .col-5 {
    width: 41.66667%;
}

.row > .off-5 {
    margin-left: 41.66667%;
}

.row > .col-6 {
    width: 50%;
}

.row > .off-6 {
    margin-left: 50%;
}

.row > .col-7 {
    width: 58.33333%;
}

.row > .off-7 {
    margin-left: 58.33333%;
}

.row > .col-8 {
    width: 66.66667%;
}

.row > .off-8 {
    margin-left: 66.66667%;
}

.row > .col-9 {
    width: 75%;
}

.row > .off-9 {
    margin-left: 75%;
}

.row > .col-10 {
    width: 83.33333%;
}

.row > .off-10 {
    margin-left: 83.33333%;
}

.row > .col-11 {
    width: 91.66667%;
}

.row > .off-11 {
    margin-left: 91.66667%;
}

.row > .col-12 {
    width: 100%;
}

.row > .off-12 {
    margin-left: 100%;
}

.row.gtr-0 {
    margin-top: 0;
    margin-left: 0em;
}

.row.gtr-0 > * {
    padding: 0 0 0 0em;
}

.row.gtr-0.gtr-uniform {
    margin-top: 0em;
}

.row.gtr-0.gtr-uniform > * {
    padding-top: 0em;
}

.row.gtr-25 {
    margin-top: 0;
    margin-left: -0.5em;
}

.row.gtr-25 > * {
    padding: 0 0 0 0.5em;
}

.row.gtr-25.gtr-uniform {
    margin-top: -0.5em;
}

.row.gtr-25.gtr-uniform > * {
    padding-top: 0.5em;
}

.row.gtr-50 {
    margin-top: 0;
    margin-left: -1em;
}

.row.gtr-50 > * {
    padding: 0 0 0 1em;
}

.row.gtr-50.gtr-uniform {
    margin-top: -1em;
}

.row.gtr-50.gtr-uniform > * {
    padding-top: 1em;
}

.row {
    margin-top: 0;
    margin-left: -2em;
}

.row > * {
    padding: 0 0 0 2em;
}

.row.gtr-uniform {
    margin-top: -2em;
}

.row.gtr-uniform > * {
    padding-top: 2em;
}

.row.gtr-150 {
    margin-top: 0;
    margin-left: -3em;
}

.row.gtr-150 > * {
    padding: 0 0 0 3em;
}

.row.gtr-150.gtr-uniform {
    margin-top: -3em;
}

.row.gtr-150.gtr-uniform > * {
    padding-top: 3em;
}

.row.gtr-200 {
    margin-top: 0;
    margin-left: -4em;
}

.row.gtr-200 > * {
    padding: 0 0 0 4em;
}

.row.gtr-200.gtr-uniform {
    margin-top: -4em;
}

.row.gtr-200.gtr-uniform > * {
    padding-top: 4em;
}

@media screen and (max-width: 1680px) {

    .row {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        align-items: stretch;
    }

    .row > * {
        box-sizing: border-box;
    }

    .row.gtr-uniform > * > :last-child {
        margin-bottom: 0;
    }

    .row.aln-left {
        justify-content: flex-start;
    }

    .row.aln-center {
        justify-content: center;
    }

    .row.aln-right {
        justify-content: flex-end;
    }

    .row.aln-top {
        align-items: flex-start;
    }

    .row.aln-middle {
        align-items: center;
    }

    .row.aln-bottom {
        align-items: flex-end;
    }

    .row > .imp-xlarge {
        order: -1;
    }

    .row > .col-1-xlarge {
        width: 8.33333%;
    }

    .row > .off-1-xlarge {
        margin-left: 8.33333%;
    }

    .row > .col-2-xlarge {
        width: 16.66667%;
    }

    .row > .off-2-xlarge {
        margin-left: 16.66667%;
    }

    .row > .col-3-xlarge {
        width: 25%;
    }

    .row > .off-3-xlarge {
        margin-left: 25%;
    }

    .row > .col-4-xlarge {
        width: 33.33333%;
    }

    .row > .off-4-xlarge {
        margin-left: 33.33333%;
    }

    .row > .col-5-xlarge {
        width: 41.66667%;
    }

    .row > .off-5-xlarge {
        margin-left: 41.66667%;
    }

    .row > .col-6-xlarge {
        width: 50%;
    }

    .row > .off-6-xlarge {
        margin-left: 50%;
    }

    .row > .col-7-xlarge {
        width: 58.33333%;
    }

    .row > .off-7-xlarge {
        margin-left: 58.33333%;
    }

    .row > .col-8-xlarge {
        width: 66.66667%;
    }

    .row > .off-8-xlarge {
        margin-left: 66.66667%;
    }

    .row > .col-9-xlarge {
        width: 75%;
    }

    .row > .off-9-xlarge {
        margin-left: 75%;
    }

    .row > .col-10-xlarge {
        width: 83.33333%;
    }

    .row > .off-10-xlarge {
        margin-left: 83.33333%;
    }

    .row > .col-11-xlarge {
        width: 91.66667%;
    }

    .row > .off-11-xlarge {
        margin-left: 91.66667%;
    }

    .row > .col-12-xlarge {
        width: 100%;
    }

    .row > .off-12-xlarge {
        margin-left: 100%;
    }

    .row.gtr-0 {
        margin-top: 0;
        margin-left: 0em;
    }

    .row.gtr-0 > * {
        padding: 0 0 0 0em;
    }

    .row.gtr-0.gtr-uniform {
        margin-top: 0em;
    }

    .row.gtr-0.gtr-uniform > * {
        padding-top: 0em;
    }

    .row.gtr-25 {
        margin-top: 0;
        margin-left: -0.5em;
    }

    .row.gtr-25 > * {
        padding: 0 0 0 0.5em;
    }

    .row.gtr-25.gtr-uniform {
        margin-top: -0.5em;
    }

    .row.gtr-25.gtr-uniform > * {
        padding-top: 0.5em;
    }

    .row.gtr-50 {
        margin-top: 0;
        margin-left: -1em;
    }

    .row.gtr-50 > * {
        padding: 0 0 0 1em;
    }

    .row.gtr-50.gtr-uniform {
        margin-top: -1em;
    }

    .row.gtr-50.gtr-uniform > * {
        padding-top: 1em;
    }

    .row {
        margin-top: 0;
        margin-left: -2em;
    }

    .row > * {
        padding: 0 0 0 2em;
    }

    .row.gtr-uniform {
        margin-top: -2em;
    }

    .row.gtr-uniform > * {
        padding-top: 2em;
    }

    .row.gtr-150 {
        margin-top: 0;
        margin-left: -3em;
    }

    .row.gtr-150 > * {
        padding: 0 0 0 3em;
    }

    .row.gtr-150.gtr-uniform {
        margin-top: -3em;
    }

    .row.gtr-150.gtr-uniform > * {
        padding-top: 3em;
    }

    .row.gtr-200 {
        margin-top: 0;
        margin-left: -4em;
    }

    .row.gtr-200 > * {
        padding: 0 0 0 4em;
    }

    .row.gtr-200.gtr-uniform {
        margin-top: -4em;
    }

    .row.gtr-200.gtr-uniform > * {
        padding-top: 4em;
    }

}

@media screen and (max-width: 1280px) {

    .row {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        align-items: stretch;
    }

    .row > * {
        box-sizing: border-box;
    }

    .row.gtr-uniform > * > :last-child {
        margin-bottom: 0;
    }

    .row.aln-left {
        justify-content: flex-start;
    }

    .row.aln-center {
        justify-content: center;
    }

    .row.aln-right {
        justify-content: flex-end;
    }

    .row.aln-top {
        align-items: flex-start;
    }

    .row.aln-middle {
        align-items: center;
    }

    .row.aln-bottom {
        align-items: flex-end;
    }

    .row > .imp-large {
        order: -1;
    }

    .row > .col-1-large {
        width: 8.33333%;
    }

    .row > .off-1-large {
        margin-left: 8.33333%;
    }

    .row > .col-2-large {
        width: 16.66667%;
    }

    .row > .off-2-large {
        margin-left: 16.66667%;
    }

    .row > .col-3-large {
        width: 25%;
    }

    .row > .off-3-large {
        margin-left: 25%;
    }

    .row > .col-4-large {
        width: 33.33333%;
    }

    .row > .off-4-large {
        margin-left: 33.33333%;
    }

    .row > .col-5-large {
        width: 41.66667%;
    }

    .row > .off-5-large {
        margin-left: 41.66667%;
    }

    .row > .col-6-large {
        width: 50%;
    }

    .row > .off-6-large {
        margin-left: 50%;
    }

    .row > .col-7-large {
        width: 58.33333%;
    }

    .row > .off-7-large {
        margin-left: 58.33333%;
    }

    .row > .col-8-large {
        width: 66.66667%;
    }

    .row > .off-8-large {
        margin-left: 66.66667%;
    }

    .row > .col-9-large {
        width: 75%;
    }

    .row > .off-9-large {
        margin-left: 75%;
    }

    .row > .col-10-large {
        width: 83.33333%;
    }

    .row > .off-10-large {
        margin-left: 83.33333%;
    }

    .row > .col-11-large {
        width: 91.66667%;
    }

    .row > .off-11-large {
        margin-left: 91.66667%;
    }

    .row > .col-12-large {
        width: 100%;
    }

    .row > .off-12-large {
        margin-left: 100%;
    }

    .row.gtr-0 {
        margin-top: 0;
        margin-left: 0em;
    }

    .row.gtr-0 > * {
        padding: 0 0 0 0em;
    }

    .row.gtr-0.gtr-uniform {
        margin-top: 0em;
    }

    .row.gtr-0.gtr-uniform > * {
        padding-top: 0em;
    }

    .row.gtr-25 {
        margin-top: 0;
        margin-left: -0.375em;
    }

    .row.gtr-25 > * {
        padding: 0 0 0 0.375em;
    }

    .row.gtr-25.gtr-uniform {
        margin-top: -0.375em;
    }

    .row.gtr-25.gtr-uniform > * {
        padding-top: 0.375em;
    }

    .row.gtr-50 {
        margin-top: 0;
        margin-left: -0.75em;
    }

    .row.gtr-50 > * {
        padding: 0 0 0 0.75em;
    }

    .row.gtr-50.gtr-uniform {
        margin-top: -0.75em;
    }

    .row.gtr-50.gtr-uniform > * {
        padding-top: 0.75em;
    }

    .row {
        margin-top: 0;
        margin-left: -1.5em;
    }

    .row > * {
        padding: 0 0 0 1.5em;
    }

    .row.gtr-uniform {
        margin-top: -1.5em;
    }

    .row.gtr-uniform > * {
        padding-top: 1.5em;
    }

    .row.gtr-150 {
        margin-top: 0;
        margin-left: -2.25em;
    }

    .row.gtr-150 > * {
        padding: 0 0 0 2.25em;
    }

    .row.gtr-150.gtr-uniform {
        margin-top: -2.25em;
    }

    .row.gtr-150.gtr-uniform > * {
        padding-top: 2.25em;
    }

    .row.gtr-200 {
        margin-top: 0;
        margin-left: -3em;
    }

    .row.gtr-200 > * {
        padding: 0 0 0 3em;
    }

    .row.gtr-200.gtr-uniform {
        margin-top: -3em;
    }

    .row.gtr-200.gtr-uniform > * {
        padding-top: 3em;
    }

}

@media screen and (max-width: 980px) {

    .row {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        align-items: stretch;
    }

    .row > * {
        box-sizing: border-box;
    }

    .row.gtr-uniform > * > :last-child {
        margin-bottom: 0;
    }

    .row.aln-left {
        justify-content: flex-start;
    }

    .row.aln-center {
        justify-content: center;
    }

    .row.aln-right {
        justify-content: flex-end;
    }

    .row.aln-top {
        align-items: flex-start;
    }

    .row.aln-middle {
        align-items: center;
    }

    .row.aln-bottom {
        align-items: flex-end;
    }

    .row > .imp-medium {
        order: -1;
    }

    .row > .col-1-medium {
        width: 8.33333%;
    }

    .row > .off-1-medium {
        margin-left: 8.33333%;
    }

    .row > .col-2-medium {
        width: 16.66667%;
    }

    .row > .off-2-medium {
        margin-left: 16.66667%;
    }

    .row > .col-3-medium {
        width: 25%;
    }

    .row > .off-3-medium {
        margin-left: 25%;
    }

    .row > .col-4-medium {
        width: 33.33333%;
    }

    .row > .off-4-medium {
        margin-left: 33.33333%;
    }

    .row > .col-5-medium {
        width: 41.66667%;
    }

    .row > .off-5-medium {
        margin-left: 41.66667%;
    }

    .row > .col-6-medium {
        width: 50%;
    }

    .row > .off-6-medium {
        margin-left: 50%;
    }

    .row > .col-7-medium {
        width: 58.33333%;
    }

    .row > .off-7-medium {
        margin-left: 58.33333%;
    }

    .row > .col-8-medium {
        width: 66.66667%;
    }

    .row > .off-8-medium {
        margin-left: 66.66667%;
    }

    .row > .col-9-medium {
        width: 75%;
    }

    .row > .off-9-medium {
        margin-left: 75%;
    }

    .row > .col-10-medium {
        width: 83.33333%;
    }

    .row > .off-10-medium {
        margin-left: 83.33333%;
    }

    .row > .col-11-medium {
        width: 91.66667%;
    }

    .row > .off-11-medium {
        margin-left: 91.66667%;
    }

    .row > .col-12-medium {
        width: 100%;
    }

    .row > .off-12-medium {
        margin-left: 100%;
    }

    .row.gtr-0 {
        margin-top: 0;
        margin-left: 0em;
    }

    .row.gtr-0 > * {
        padding: 0 0 0 0em;
    }

    .row.gtr-0.gtr-uniform {
        margin-top: 0em;
    }

    .row.gtr-0.gtr-uniform > * {
        padding-top: 0em;
    }

    .row.gtr-25 {
        margin-top: 0;
        margin-left: -0.375em;
    }

    .row.gtr-25 > * {
        padding: 0 0 0 0.375em;
    }

    .row.gtr-25.gtr-uniform {
        margin-top: -0.375em;
    }

    .row.gtr-25.gtr-uniform > * {
        padding-top: 0.375em;
    }

    .row.gtr-50 {
        margin-top: 0;
        margin-left: -0.75em;
    }

    .row.gtr-50 > * {
        padding: 0 0 0 0.75em;
    }

    .row.gtr-50.gtr-uniform {
        margin-top: -0.75em;
    }

    .row.gtr-50.gtr-uniform > * {
        padding-top: 0.75em;
    }

    .row {
        margin-top: 0;
        margin-left: -1.5em;
    }

    .row > * {
        padding: 0 0 0 1.5em;
    }

    .row.gtr-uniform {
        margin-top: -1.5em;
    }

    .row.gtr-uniform > * {
        padding-top: 1.5em;
    }

    .row.gtr-150 {
        margin-top: 0;
        margin-left: -2.25em;
    }

    .row.gtr-150 > * {
        padding: 0 0 0 2.25em;
    }

    .row.gtr-150.gtr-uniform {
        margin-top: -2.25em;
    }

    .row.gtr-150.gtr-uniform > * {
        padding-top: 2.25em;
    }

    .row.gtr-200 {
        margin-top: 0;
        margin-left: -3em;
    }

    .row.gtr-200 > * {
        padding: 0 0 0 3em;
    }

    .row.gtr-200.gtr-uniform {
        margin-top: -3em;
    }

    .row.gtr-200.gtr-uniform > * {
        padding-top: 3em;
    }

}

@media screen and (max-width: 736px) {

    .row {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        align-items: stretch;
    }

    .row > * {
        box-sizing: border-box;
    }

    .row.gtr-uniform > * > :last-child {
        margin-bottom: 0;
    }

    .row.aln-left {
        justify-content: flex-start;
    }

    .row.aln-center {
        justify-content: center;
    }

    .row.aln-right {
        justify-content: flex-end;
    }

    .row.aln-top {
        align-items: flex-start;
    }

    .row.aln-middle {
        align-items: center;
    }

    .row.aln-bottom {
        align-items: flex-end;
    }

    .row > .imp-small {
        order: -1;
    }

    .row > .col-1-small {
        width: 8.33333%;
    }

    .row > .off-1-small {
        margin-left: 8.33333%;
    }

    .row > .col-2-small {
        width: 16.66667%;
    }

    .row > .off-2-small {
        margin-left: 16.66667%;
    }

    .row > .col-3-small {
        width: 25%;
    }

    .row > .off-3-small {
        margin-left: 25%;
    }

    .row > .col-4-small {
        width: 33.33333%;
    }

    .row > .off-4-small {
        margin-left: 33.33333%;
    }

    .row > .col-5-small {
        width: 41.66667%;
    }

    .row > .off-5-small {
        margin-left: 41.66667%;
    }

    .row > .col-6-small {
        width: 50%;
    }

    .row > .off-6-small {
        margin-left: 50%;
    }

    .row > .col-7-small {
        width: 58.33333%;
    }

    .row > .off-7-small {
        margin-left: 58.33333%;
    }

    .row > .col-8-small {
        width: 66.66667%;
    }

    .row > .off-8-small {
        margin-left: 66.66667%;
    }

    .row > .col-9-small {
        width: 75%;
    }

    .row > .off-9-small {
        margin-left: 75%;
    }

    .row > .col-10-small {
        width: 83.33333%;
    }

    .row > .off-10-small {
        margin-left: 83.33333%;
    }

    .row > .col-11-small {
        width: 91.66667%;
    }

    .row > .off-11-small {
        margin-left: 91.66667%;
    }

    .row > .col-12-small {
        width: 100%;
    }

    .row > .off-12-small {
        margin-left: 100%;
    }

    .row.gtr-0 {
        margin-top: 0;
        margin-left: 0em;
    }

    .row.gtr-0 > * {
        padding: 0 0 0 0em;
    }

    .row.gtr-0.gtr-uniform {
        margin-top: 0em;
    }

    .row.gtr-0.gtr-uniform > * {
        padding-top: 0em;
    }

    .row.gtr-25 {
        margin-top: 0;
        margin-left: -0.3125em;
    }

    .row.gtr-25 > * {
        padding: 0 0 0 0.3125em;
    }

    .row.gtr-25.gtr-uniform {
        margin-top: -0.3125em;
    }

    .row.gtr-25.gtr-uniform > * {
        padding-top: 0.3125em;
    }

    .row.gtr-50 {
        margin-top: 0;
        margin-left: -0.625em;
    }

    .row.gtr-50 > * {
        padding: 0 0 0 0.625em;
    }

    .row.gtr-50.gtr-uniform {
        margin-top: -0.625em;
    }

    .row.gtr-50.gtr-uniform > * {
        padding-top: 0.625em;
    }

    .row {
        margin-top: 0;
        margin-left: -1.25em;
    }

    .row > * {
        padding: 0 0 0 1.25em;
    }

    .row.gtr-uniform {
        margin-top: -1.25em;
    }

    .row.gtr-uniform > * {
        padding-top: 1.25em;
    }

    .row.gtr-150 {
        margin-top: 0;
        margin-left: -1.875em;
    }

    .row.gtr-150 > * {
        padding: 0 0 0 1.875em;
    }

    .row.gtr-150.gtr-uniform {
        margin-top: -1.875em;
    }

    .row.gtr-150.gtr-uniform > * {
        padding-top: 1.875em;
    }

    .row.gtr-200 {
        margin-top: 0;
        margin-left: -2.5em;
    }

    .row.gtr-200 > * {
        padding: 0 0 0 2.5em;
    }

    .row.gtr-200.gtr-uniform {
        margin-top: -2.5em;
    }

    .row.gtr-200.gtr-uniform > * {
        padding-top: 2.5em;
    }

}

@media screen and (max-width: 480px) {

    .row {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        align-items: stretch;
    }

    .row > * {
        box-sizing: border-box;
    }

    .row.gtr-uniform > * > :last-child {
        margin-bottom: 0;
    }

    .row.aln-left {
        justify-content: flex-start;
    }

    .row.aln-center {
        justify-content: center;
    }

    .row.aln-right {
        justify-content: flex-end;
    }

    .row.aln-top {
        align-items: flex-start;
    }

    .row.aln-middle {
        align-items: center;
    }

    .row.aln-bottom {
        align-items: flex-end;
    }

    .row > .imp-xsmall {
        order: -1;
    }

    .row > .col-1-xsmall {
        width: 8.33333%;
    }

    .row > .off-1-xsmall {
        margin-left: 8.33333%;
    }

    .row > .col-2-xsmall {
        width: 16.66667%;
    }

    .row > .off-2-xsmall {
        margin-left: 16.66667%;
    }

    .row > .col-3-xsmall {
        width: 25%;
    }

    .row > .off-3-xsmall {
        margin-left: 25%;
    }

    .row > .col-4-xsmall {
        width: 33.33333%;
    }

    .row > .off-4-xsmall {
        margin-left: 33.33333%;
    }

    .row > .col-5-xsmall {
        width: 41.66667%;
    }

    .row > .off-5-xsmall {
        margin-left: 41.66667%;
    }

    .row > .col-6-xsmall {
        width: 50%;
    }

    .row > .off-6-xsmall {
        margin-left: 50%;
    }

    .row > .col-7-xsmall {
        width: 58.33333%;
    }

    .row > .off-7-xsmall {
        margin-left: 58.33333%;
    }

    .row > .col-8-xsmall {
        width: 66.66667%;
    }

    .row > .off-8-xsmall {
        margin-left: 66.66667%;
    }

    .row > .col-9-xsmall {
        width: 75%;
    }

    .row > .off-9-xsmall {
        margin-left: 75%;
    }

    .row > .col-10-xsmall {
        width: 83.33333%;
    }

    .row > .off-10-xsmall {
        margin-left: 83.33333%;
    }

    .row > .col-11-xsmall {
        width: 91.66667%;
    }

    .row > .off-11-xsmall {
        margin-left: 91.66667%;
    }

    .row > .col-12-xsmall {
        width: 100%;
    }

    .row > .off-12-xsmall {
        margin-left: 100%;
    }

    .row.gtr-0 {
        margin-top: 0;
        margin-left: 0em;
    }

    .row.gtr-0 > * {
        padding: 0 0 0 0em;
    }

    .row.gtr-0.gtr-uniform {
        margin-top: 0em;
    }

    .row.gtr-0.gtr-uniform > * {
        padding-top: 0em;
    }

    .row.gtr-25 {
        margin-top: 0;
        margin-left: -0.3125em;
    }

    .row.gtr-25 > * {
        padding: 0 0 0 0.3125em;
    }

    .row.gtr-25.gtr-uniform {
        margin-top: -0.3125em;
    }

    .row.gtr-25.gtr-uniform > * {
        padding-top: 0.3125em;
    }

    .row.gtr-50 {
        margin-top: 0;
        margin-left: -0.625em;
    }

    .row.gtr-50 > * {
        padding: 0 0 0 0.625em;
    }

    .row.gtr-50.gtr-uniform {
        margin-top: -0.625em;
    }

    .row.gtr-50.gtr-uniform > * {
        padding-top: 0.625em;
    }

    .row {
        margin-top: 0;
        margin-left: -1.25em;
    }

    .row > * {
        padding: 0 0 0 1.25em;
    }

    .row.gtr-uniform {
        margin-top: -1.25em;
    }

    .row.gtr-uniform > * {
        padding-top: 1.25em;
    }

    .row.gtr-150 {
        margin-top: 0;
        margin-left: -1.875em;
    }

    .row.gtr-150 > * {
        padding: 0 0 0 1.875em;
    }

    .row.gtr-150.gtr-uniform {
        margin-top: -1.875em;
    }

    .row.gtr-150.gtr-uniform > * {
        padding-top: 1.875em;
    }

    .row.gtr-200 {
        margin-top: 0;
        margin-left: -2.5em;
    }

    .row.gtr-200 > * {
        padding: 0 0 0 2.5em;
    }

    .row.gtr-200.gtr-uniform {
        margin-top: -2.5em;
    }

    .row.gtr-200.gtr-uniform > * {
        padding-top: 2.5em;
    }

}

/* Section/Article */

section.special, article.special, header.special {
    text-align: center;
}

header p {
    position: relative;
    margin: 0 0 1.5em 0;
}

header h2 + p {
    font-size: 1.25em;
    margin-top: -0.8em;
}

header h3 + p {
    font-size: 1.1em;
    margin-top: -0.6em;
}

header h4 + p,
header h5 + p,
header h6 + p {
    font-size: 0.9em;
    margin-top: -0.3em;
}

header small {
    display: block;
    font-size: 0.85em;
    font-family: "Josefin Sans", Arial, Helvetica, sans-serif;
    letter-spacing: 0.1em;
    margin-bottom: 0.3em;
    text-transform: uppercase;
}

header.major {
    margin-bottom: 6em;
}

header.narrow {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 980px) {

    header.major {
        margin-bottom: 5em;
    }

    header.narrow {
        width: auto;
    }

}

@media screen and (max-width: 736px) {

    header h2 + p {
        font-size: 1em;
    }

    header h3 + p {
        font-size: 1em;
    }

    header.major {
        margin-bottom: 3em;
    }

}

@media screen and (max-width: 480px) {

    header.major {
        margin-bottom: 2em;
    }

}

/* Form */

form {
    margin: 0 0 2em 0;
}

form ul.actions {
    margin-top: 2em;
}

label {
    display: block;
    font-size: 0.9em;
    font-weight: 400;
    margin: 0 0 1em 0;
}

input[type="text"],
input[type="password"],
input[type="email"],
select,
textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    display: block;
    outline: 0;
    padding: 0 1em;
    text-decoration: none;
    width: 100%;
}

input[type="text"]:invalid,
input[type="password"]:invalid,
input[type="email"]:invalid,
select:invalid,
textarea:invalid {
    box-shadow: none;
}

select {
    background-size: 1.25em;
    background-repeat: no-repeat;
    background-position: calc(100% - 1em) center;
    height: 2.75em;
    padding-right: 2.75em;
    text-overflow: ellipsis;
}

select:focus::-ms-value {
    background-color: transparent;
}

select::-ms-expand {
    display: none;
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
    height: 2.75em;
}

textarea {
    padding: 0.75em 1em;
}

input[type="checkbox"],
input[type="radio"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    display: block;
    float: left;
    margin-right: -2em;
    opacity: 0;
    width: 1em;
    z-index: -1;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: 300;
    padding-left: 2.4em;
    padding-right: 0.75em;
    position: relative;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    text-transform: none !important;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    content: '';
    display: inline-block;
    font-size: 0.8em;
    height: 2.0625em;
    left: 0;
    line-height: 2.0625em;
    position: absolute;
    text-align: center;
    top: 0;
    width: 2.0625em;
}

input[type="checkbox"]:checked + label:before,
input[type="radio"]:checked + label:before {
    content: '\f00c';
}

input[type="checkbox"] + label:before {
    border-radius: 0;
}

input[type="radio"] + label:before {
    border-radius: 100%;
}

::-webkit-input-placeholder {
    opacity: 1.0;
}

:-moz-placeholder {
    opacity: 1.0;
}

::-moz-placeholder {
    opacity: 1.0;
}

:-ms-input-placeholder {
    opacity: 1.0;
}

label {
    color: #555;
}

input[type="text"],
input[type="password"],
input[type="email"],
select,
textarea {
    background-color: rgba(144, 144, 144, 0.075);
    border-color: rgba(144, 144, 144, 0.25);
    color: inherit;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
select:focus,
textarea:focus {
    border-color: #65bfad;
    box-shadow: 0 0 0 1px #65bfad;
}

select {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(144, 144, 144, 0.25)' /%3E%3C/svg%3E");
}

select option {
    color: #555;
    background: #fff;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
    color: #777;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
    background-color: rgba(144, 144, 144, 0.075);
    border-color: rgba(144, 144, 144, 0.25);
}

input[type="checkbox"]:checked + label:before,
input[type="radio"]:checked + label:before {
    background-color: #65bfad;
    border-color: #65bfad;
    color: #fff;
}

input[type="checkbox"]:focus + label:before,
input[type="radio"]:focus + label:before {
    border-color: #65bfad;
    box-shadow: 0 0 0 1px #65bfad;
}

::-webkit-input-placeholder {
    color: #bbb !important;
}

:-moz-placeholder {
    color: #bbb !important;
}

::-moz-placeholder {
    color: #bbb !important;
}

:-ms-input-placeholder {
    color: #bbb !important;
}

.formerize-placeholder {
    color: #bbb !important;
}

form.alt input[type="text"],
form.alt input[type="password"],
form.alt input[type="email"],
form.alt select,
form.alt textarea {
    background-color: transparent;
}

/* Box */

.box {
    border-radius: 0;
    border: solid 1px rgba(144, 144, 144, 0.25);
    margin-bottom: 2em;
    padding: 1.5em;
}

.box > :last-child,
.box > :last-child > :last-child,
.box > :last-child > :last-child > :last-child {
    margin-bottom: 0;
}

.box.alt {
    border: 0;
    border-radius: 0;
    padding: 0;
}

/* Icon */

.icon {
    text-decoration: none;
    border-bottom: none;
    position: relative;
}

.icon:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    text-transform: none !important;
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
}

.icon.solid:before {
    font-weight: 900;
}

.icon.brands:before {
    font-family: 'Font Awesome 5 Brands';
}

.icon > .label {
    display: none;
}

.icon.alt:before {
    color: #fff;
    text-shadow: 1px 0px 0px #555, -1px 0px 0px #555, 0px 1px 0px #555, 0px -1px 0px #555;
}

/* Image */

.image {
    border-radius: 0;
    border: 0;
    display: inline-block;
    position: relative;
}

.image img {
    border-radius: 0;
    display: block;
}

.image.left, .image.right {
    max-width: 40%;
}

.image.left img, .image.right img {
    width: 100%;
}

.image.left {
    float: left;
    padding: 0 1.5em 1em 0;
    top: 0.25em;
}

.image.right {
    float: right;
    padding: 0 0 1em 1.5em;
    top: 0.25em;
}

.image.fit {
    display: block;
    margin: 0 0 2em 0;
    width: 100%;
}

.image.fit img {
    width: 100%;
}

.image.main {
    display: block;
    margin: 0 0 3em 0;
    width: 100%;
}

.image.main img {
    width: 100%;
}

/* List */

ol {
    list-style: decimal;
    margin: 0 0 2em 0;
    padding-left: 1.25em;
}

ol li {
    padding-left: 0.25em;
}

ul {
    list-style: disc;
    margin: 0 0 2em 0;
    padding-left: 1em;
}

ul li {
    padding-left: 0.5em;
}

ul.alt {
    list-style: none;
    padding-left: 0;
}

ul.alt > li {
    border-top-style: solid;
    border-top-width: 1px;
    padding: 0.5em 0;
}

ul.alt > li:first-child {
    border-top: 0;
    padding-top: 0;
}

dl {
    margin: 0 0 2em 0;
}

dl > dt {
    display: block;
    font-weight: 400;
    margin: 0 0 1em 0;
}

dl > dd {
    margin-left: 2em;
}

ul.alt > li {
    border-top-color: rgba(144, 144, 144, 0.25);
}

/* Actions */

ul.actions {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    cursor: default;
    list-style: none;
    margin-left: -1em;
    padding-left: 0;
}

ul.actions li {
    padding: 0 0 0 1em;
    vertical-align: middle;
}

ul.actions.special {
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    width: 100%;
    margin-left: 0;
}

ul.actions.special li:first-child {
    padding-left: 0;
}

ul.actions.stacked {
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 0;
}

ul.actions.stacked li {
    padding: 1.3em 0 0 0;
}

ul.actions.stacked li:first-child {
    padding-top: 0;
}

ul.actions.fit {
    width: calc(100% + 1em);
}

ul.actions.fit li {
    -moz-flex-grow: 1;
    -webkit-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    -moz-flex-shrink: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-shrink: 1;
    flex-shrink: 1;
    width: 100%;
}

ul.actions.fit li > * {
    width: 100%;
}

ul.actions.fit.stacked {
    width: 100%;
}

@media screen and (max-width: 480px) {

    ul.actions:not(.fixed) {
        -moz-flex-direction: column;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-left: 0;
        width: 100% !important;
    }

    ul.actions:not(.fixed) li {
        -moz-flex-grow: 1;
        -webkit-flex-grow: 1;
        -ms-flex-grow: 1;
        flex-grow: 1;
        -moz-flex-shrink: 1;
        -webkit-flex-shrink: 1;
        -ms-flex-shrink: 1;
        flex-shrink: 1;
        padding: 1em 0 0 0;
        text-align: center;
        width: 100%;
    }

    ul.actions:not(.fixed) li > * {
        width: 100%;
    }

    ul.actions:not(.fixed) li:first-child {
        padding-top: 0;
    }

    ul.actions:not(.fixed) li input[type="submit"],
    ul.actions:not(.fixed) li input[type="reset"],
    ul.actions:not(.fixed) li input[type="button"],
    ul.actions:not(.fixed) li button,
    ul.actions:not(.fixed) li .button {
        width: 100%;
    }

    ul.actions:not(.fixed) li input[type="submit"].icon:before,
    ul.actions:not(.fixed) li input[type="reset"].icon:before,
    ul.actions:not(.fixed) li input[type="button"].icon:before,
    ul.actions:not(.fixed) li button.icon:before,
    ul.actions:not(.fixed) li .button.icon:before {
        margin-left: -0.5em;
    }

}

/* Icons */

ul.icons {
    cursor: default;
    list-style: none;
    padding-left: 0;
}

ul.icons > li {
    display: inline-block;
    padding: 0 1em 0 0;
}

ul.icons > li:last-child {
    padding-right: 0;
}

ul.icons > li .icon:before {
    font-size: 2em;
    line-height: inherit;
}

ul.icons.major {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin-bottom: 4em;
}

ul.icons.major > li {
    padding: 0;
}

ul.icons.major > li:first-child:before {
    content: '';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    margin-top: 3.75em;
    width: 100%;
}

ul.icons.major > li > .icon {
    border-radius: 100%;
    border-style: solid;
    border-width: 2px;
    display: block;
    height: 100%;
    text-align: center;
    width: 4.25em;
    height: 4.25em;
    line-height: 4em;
    font-size: 1.75em;
}

ul.icons.major > li > .icon:before {
    position: relative;
}

ul.icons.major > li.special {
    border-width: 0;
}

@media screen and (max-width: 736px) {

    ul.icons.major li:first-child:before {
        margin-top: 2.5em;
    }

    ul.icons.major li > .icon {
        font-size: 1.25em;
    }

}

@media screen and (max-width: 480px) {

    ul.icons.major li:first-child:before {
        margin-top: 2em;
    }

    ul.icons.major li > .icon {
        font-size: 1em;
    }

}

ul.icons.major > li:first-child:before {
    background-color: rgba(144, 144, 144, 0.25);
}

ul.icons.major > li > .icon {
    background-color: #fff;
    border-color: rgba(144, 144, 144, 0.25);
    color: rgba(144, 144, 144, 0.5);
}

ul.icons.major > li > .icon.alt:before {
    color: #fff;
    text-shadow: 1px 0px 0px rgba(144, 144, 144, 0.5), -1px 0px 0px rgba(144, 144, 144, 0.5), 0px 1px 0px rgba(144, 144, 144, 0.5), 0px -1px 0px rgba(144, 144, 144, 0.5);
}

ul.icons.major > li.special > .icon {
    background-color: #65bfad;
    border-color: transparent;
    color: #fff;
}

ul.icons.major > li.special > .icon.alt:before {
    color: #65bfad;
    text-shadow: 1px 0px 0px #fff, -1px 0px 0px #fff, 0px 1px 0px #fff, 0px -1px 0px #fff;
}

/* Labeled Icons */

ul.labeled-icons {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    list-style: none;
    padding-left: 0;
    text-align: center;
}

ul.labeled-icons > li {
    border-left-style: solid;
    border-left-width: 1px;
    padding: 0 2em;
}

ul.labeled-icons > li:first-child {
    border-left-width: 0;
}

ul.labeled-icons .icon:before {
    display: block;
}

@media screen and (max-width: 480px) {

    ul.labeled-icons {
        -moz-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    ul.labeled-icons > li {
        border-left-width: 0;
        margin-bottom: 1em;
    }

}

ul.labeled-icons > li {
    border-left-color: rgba(144, 144, 144, 0.25);
}

ul.labeled-icons > li > .icon:before {
    color: #65bfad;
}

/* Checklist */

ul.checklist {
    list-style: none;
    padding-left: 0;
}

ul.checklist li {
    padding-left: 0;
    text-decoration: none;
}

ul.checklist li:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    text-transform: none !important;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

ul.checklist li:before {
    content: '\f00c';
    margin-right: 0.75em;
}

ul.checklist li:before {
    color: #777;
}

/* Table */

.table-wrapper {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
}

table {
    margin: 0 0 2em 0;
    width: 100%;
}

table tbody tr {
    border-style: solid;
    border-width: 1px;
    border-left: 0;
    border-right: 0;
}

table td {
    padding: 0.75em 0.75em;
}

table th {
    font-size: 0.9em;
    font-weight: 400;
    padding: 0 0.75em 0.75em 0.75em;
    text-align: left;
}

table thead {
    border-bottom-style: solid;
    border-bottom-width: 2px;
}

table tfoot {
    border-top-style: solid;
    border-top-width: 2px;
}

table.alt {
    border-collapse: separate;
}

table.alt tbody tr td {
    border-style: solid;
    border-width: 1px;
    border-left-width: 0;
    border-top-width: 0;
}

table.alt tbody tr td:first-child {
    border-left-width: 1px;
}

table.alt tbody tr:first-child td {
    border-top-width: 1px;
}

table.alt thead {
    border-bottom: 0;
}

table.alt tfoot {
    border-top: 0;
}

table tbody tr {
    border-color: rgba(144, 144, 144, 0.25);
}

table tbody tr:nth-child(2n + 1) {
    background-color: rgba(144, 144, 144, 0.075);
}

table th {
    color: #555;
}

table thead {
    border-bottom-color: rgba(144, 144, 144, 0.25);
}

table tfoot {
    border-top-color: rgba(144, 144, 144, 0.25);
}

table.alt tbody tr td {
    border-color: rgba(144, 144, 144, 0.25);
}

/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    border-radius: 0;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-family: "Josefin Sans", Arial, Helvetica, sans-serif;
    font-size: 0.9em;
    height: 3.7em;
    letter-spacing: 0.1em;
    line-height: 3.85em;
    padding: 0 3.5em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
}

input[type="submit"].icon:before,
input[type="reset"].icon:before,
input[type="button"].icon:before,
button.icon:before,
.button.icon:before {
    margin-right: 0.5em;
}

input[type="submit"].fit,
input[type="reset"].fit,
input[type="button"].fit,
button.fit,
.button.fit {
    width: 100%;
}

input[type="submit"].small,
input[type="reset"].small,
input[type="button"].small,
button.small,
.button.small {
    font-size: 0.7em;
}

input[type="submit"].large,
input[type="reset"].large,
input[type="button"].large,
button.large,
.button.large {
    font-size: 1.35em;
}

input[type="submit"].disabled, input[type="submit"]:disabled,
input[type="reset"].disabled,
input[type="reset"]:disabled,
input[type="button"].disabled,
input[type="button"]:disabled,
button.disabled,
button:disabled,
.button.disabled,
.button:disabled {
    cursor: default;
    opacity: 0.25;
}

@media screen and (max-width: 480px) {

    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    button,
    .button {
        padding: 0;
    }

}

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px rgba(144, 144, 144, 0.25);
    color: #555 !important;
}

input[type="submit"].icon:before,
input[type="reset"].icon:before,
input[type="button"].icon:before,
button.icon:before,
.button.icon:before {
    color: #bbb;
}

input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover,
.button:hover {
    background-color: rgba(144, 144, 144, 0.075);
}

input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:active,
button:active,
.button:active {
    background-color: rgba(144, 144, 144, 0.2);
}

input[type="submit"].primary,
input[type="reset"].primary,
input[type="button"].primary,
button.primary,
.button.primary {
    background-color: #65bfad;
    box-shadow: none;
    color: #fff !important;
}

input[type="submit"].primary:hover,
input[type="reset"].primary:hover,
input[type="button"].primary:hover,
button.primary:hover,
.button.primary:hover {
    background-color: #77c6b7;
}

input[type="submit"].primary:active,
input[type="reset"].primary:active,
input[type="button"].primary:active,
button.primary:active,
.button.primary:active {
    background-color: #53b8a3;
}

input[type="submit"].primary.icon:before,
input[type="reset"].primary.icon:before,
input[type="button"].primary.icon:before,
button.primary.icon:before,
.button.primary.icon:before {
    color: #fff;
}

input[type="submit"].alt,
input[type="reset"].alt,
input[type="button"].alt,
button.alt,
.button.alt {
    background-color: #555;
    box-shadow: none;
    color: #fff !important;
}

input[type="submit"].alt:hover,
input[type="reset"].alt:hover,
input[type="button"].alt:hover,
button.alt:hover,
.button.alt:hover {
    background-color: #626262;
}

input[type="submit"].alt:active,
input[type="reset"].alt:active,
input[type="button"].alt:active,
button.alt:active,
.button.alt:active {
    background-color: #484848;
}

/* Wrapper */

.wrapper {
    padding: 6em 0 4em 0 ;
    position: relative;
}

.wrapper > .inner {
    margin: 0 auto;
    width: 60em;
}

.wrapper.style1 {
    background-color: #65bfad;
    color: #ffffff;
}

.wrapper.style1 input, .wrapper.style1 select, .wrapper.style1 textarea {
    color: #ffffff;
}

.wrapper.style1 a {
    color: #7f4d8d;
}

.wrapper.style1 strong, .wrapper.style1 b {
    color: #ffffff;
}

.wrapper.style1 h1, .wrapper.style1 h2, .wrapper.style1 h3, .wrapper.style1 h4, .wrapper.style1 h5, .wrapper.style1 h6 {
    color: #ffffff;
}

.wrapper.style1 blockquote {
    border-left-color: #fff;
}

.wrapper.style1 code {
    background: rgba(255, 255, 255, 0.075);
    border-color: #fff;
}

.wrapper.style1 hr {
    border-bottom-color: #fff;
}

.wrapper.style1 label {
    color: #ffffff;
}

.wrapper.style1 input[type="text"],
.wrapper.style1 input[type="password"],
.wrapper.style1 input[type="email"],
.wrapper.style1 select,
.wrapper.style1 textarea {
    background-color: rgba(255, 255, 255, 0.075);
    border-color: #fff;
    color: inherit;
}

.wrapper.style1 input[type="text"]:focus,
.wrapper.style1 input[type="password"]:focus,
.wrapper.style1 input[type="email"]:focus,
.wrapper.style1 select:focus,
.wrapper.style1 textarea:focus {
    border-color: #7f4d8d;
    box-shadow: 0 0 0 1px #7f4d8d;
}

.wrapper.style1 select {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='%23fff' /%3E%3C/svg%3E");
}

.wrapper.style1 select option {
    color: #ffffff;
    background: #65bfad;
}

.wrapper.style1 input[type="checkbox"] + label,
.wrapper.style1 input[type="radio"] + label {
    color: #f1e9f3;
}

.wrapper.style1 input[type="checkbox"] + label:before,
.wrapper.style1 input[type="radio"] + label:before {
    background-color: rgba(255, 255, 255, 0.075);
    border-color: #fff;
}

.wrapper.style1 input[type="checkbox"]:checked + label:before,
.wrapper.style1 input[type="radio"]:checked + label:before {
    background-color: #7f4d8d;
    border-color: #7f4d8d;
    color: #65bfad;
}

.wrapper.style1 input[type="checkbox"]:focus + label:before,
.wrapper.style1 input[type="radio"]:focus + label:before {
    border-color: #7f4d8d;
    box-shadow: 0 0 0 1px #7f4d8d;
}

.wrapper.style1 ::-webkit-input-placeholder {
    color: #e8dbeb !important;
}

.wrapper.style1 :-moz-placeholder {
    color: #e8dbeb !important;
}

.wrapper.style1 ::-moz-placeholder {
    color: #e8dbeb !important;
}

.wrapper.style1 :-ms-input-placeholder {
    color: #e8dbeb !important;
}

.wrapper.style1 .formerize-placeholder {
    color: #e8dbeb !important;
}

.wrapper.style1 form.alt input[type="text"],
.wrapper.style1 form.alt input[type="password"],
.wrapper.style1 form.alt input[type="email"],
.wrapper.style1 form.alt select,
.wrapper.style1 form.alt textarea {
    background-color: transparent;
}

.wrapper.style1 ul.alt > li {
    border-top-color: #fff;
}

.wrapper.style1 ul.icons.major > li:first-child:before {
    background-color: #fff;
}

.wrapper.style1 ul.icons.major > li > .icon {
    background-color: #65bfad;
    border-color: #fff;
    color: #fff;
}

.wrapper.style1 ul.icons.major > li > .icon.alt:before {
    color: #65bfad;
    text-shadow: 1px 0px 0px #fff, -1px 0px 0px #fff, 0px 1px 0px #fff, 0px -1px 0px #fff;
}

.wrapper.style1 ul.icons.major > li.special > .icon {
    background-color: #7f4d8d;
    border-color: transparent;
    color: #65bfad;
}

.wrapper.style1 ul.icons.major > li.special > .icon.alt:before {
    color: #7f4d8d;
    text-shadow: 1px 0px 0px #65bfad, -1px 0px 0px #65bfad, 0px 1px 0px #65bfad, 0px -1px 0px #65bfad;
}

.wrapper.style1 ul.labeled-icons > li {
    border-left-color: #fff;
}

.wrapper.style1 ul.labeled-icons > li > .icon:before {
    color: #7f4d8d;
}

.wrapper.style1 ul.checklist li:before {
    color: #f1e9f3;
}

.wrapper.style1 table tbody tr {
    border-color: #fff;
}

.wrapper.style1 table tbody tr:nth-child(2n + 1) {
    background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style1 table th {
    color: #ffffff;
}

.wrapper.style1 table thead {
    border-bottom-color: #fff;
}

.wrapper.style1 table tfoot {
    border-top-color: #fff;
}

.wrapper.style1 table.alt tbody tr td {
    border-color: #fff;
}

.wrapper.style1 input[type="submit"],
.wrapper.style1 input[type="reset"],
.wrapper.style1 input[type="button"],
.wrapper.style1 button,
.wrapper.style1 .button {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #fff;
    color: #ffffff !important;
}

.wrapper.style1 input[type="submit"].icon:before,
.wrapper.style1 input[type="reset"].icon:before,
.wrapper.style1 input[type="button"].icon:before,
.wrapper.style1 button.icon:before,
.wrapper.style1 .button.icon:before {
    color: #e8dbeb;
}

.wrapper.style1 input[type="submit"]:hover,
.wrapper.style1 input[type="reset"]:hover,
.wrapper.style1 input[type="button"]:hover,
.wrapper.style1 button:hover,
.wrapper.style1 .button:hover {
    background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style1 input[type="submit"]:active,
.wrapper.style1 input[type="reset"]:active,
.wrapper.style1 input[type="button"]:active,
.wrapper.style1 button:active,
.wrapper.style1 .button:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.wrapper.style1 input[type="submit"].primary,
.wrapper.style1 input[type="reset"].primary,
.wrapper.style1 input[type="button"].primary,
.wrapper.style1 button.primary,
.wrapper.style1 .button.primary {
    background-color: #7f4d8d;
    box-shadow: none;
    color: #65bfad !important;
}

.wrapper.style1 input[type="submit"].primary:hover,
.wrapper.style1 input[type="reset"].primary:hover,
.wrapper.style1 input[type="button"].primary:hover,
.wrapper.style1 button.primary:hover,
.wrapper.style1 .button.primary:hover {
    background-color: #8e569e;
}

.wrapper.style1 input[type="submit"].primary:active,
.wrapper.style1 input[type="reset"].primary:active,
.wrapper.style1 input[type="button"].primary:active,
.wrapper.style1 button.primary:active,
.wrapper.style1 .button.primary:active {
    background-color: #70447d;
}

.wrapper.style1 input[type="submit"].primary.icon:before,
.wrapper.style1 input[type="reset"].primary.icon:before,
.wrapper.style1 input[type="button"].primary.icon:before,
.wrapper.style1 button.primary.icon:before,
.wrapper.style1 .button.primary.icon:before {
    color: #65bfad;
}

.wrapper.style1 input[type="submit"].alt,
.wrapper.style1 input[type="reset"].alt,
.wrapper.style1 input[type="button"].alt,
.wrapper.style1 button.alt,
.wrapper.style1 .button.alt {
    background-color: #ffffff;
    box-shadow: none;
    color: #65bfad !important;
}

.wrapper.style1 input[type="submit"].alt:hover,
.wrapper.style1 input[type="reset"].alt:hover,
.wrapper.style1 input[type="button"].alt:hover,
.wrapper.style1 button.alt:hover,
.wrapper.style1 .button.alt:hover {
    background-color: white;
}

.wrapper.style1 input[type="submit"].alt:active,
.wrapper.style1 input[type="reset"].alt:active,
.wrapper.style1 input[type="button"].alt:active,
.wrapper.style1 button.alt:active,
.wrapper.style1 .button.alt:active {
    background-color: #f2f2f2;
}

.wrapper.style1 .icon.alt:before {
    color: #65bfad;
    text-shadow: 1px 0px 0px #ffffff, -1px 0px 0px #ffffff, 0px 1px 0px #ffffff, 0px -1px 0px #ffffff;
}

.wrapper.style2 {
    background-color: #474a4c;
    color: #ffffff;
}

.wrapper.style2 input, .wrapper.style2 select, .wrapper.style2 textarea {
    color: #ffffff;
}

.wrapper.style2 a {
    color: #ffffff;
}

.wrapper.style2 strong, .wrapper.style2 b {
    color: #ffffff;
}

.wrapper.style2 h1, .wrapper.style2 h2, .wrapper.style2 h3, .wrapper.style2 h4, .wrapper.style2 h5, .wrapper.style2 h6 {
    color: #ffffff;
}

.wrapper.style2 blockquote {
    border-left-color: rgba(255, 255, 255, 0.15);
}

.wrapper.style2 code {
    background: rgba(255, 255, 255, 0.075);
    border-color: rgba(255, 255, 255, 0.15);
}

.wrapper.style2 hr {
    border-bottom-color: rgba(255, 255, 255, 0.15);
}

.wrapper.style2 label {
    color: #ffffff;
}

.wrapper.style2 input[type="text"],
.wrapper.style2 input[type="password"],
.wrapper.style2 input[type="email"],
.wrapper.style2 select,
.wrapper.style2 textarea {
    background-color: rgba(255, 255, 255, 0.075);
    border-color: rgba(255, 255, 255, 0.15);
    color: inherit;
}

.wrapper.style2 input[type="text"]:focus,
.wrapper.style2 input[type="password"]:focus,
.wrapper.style2 input[type="email"]:focus,
.wrapper.style2 select:focus,
.wrapper.style2 textarea:focus {
    border-color: #ffffff;
    box-shadow: 0 0 0 1px #ffffff;
}

.wrapper.style2 select {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(255, 255, 255, 0.15)' /%3E%3C/svg%3E");
}

.wrapper.style2 select option {
    color: #ffffff;
    background: #474a4c;
}

.wrapper.style2 input[type="checkbox"] + label,
.wrapper.style2 input[type="radio"] + label {
    color: #d3d2d3;
}

.wrapper.style2 input[type="checkbox"] + label:before,
.wrapper.style2 input[type="radio"] + label:before {
    background-color: rgba(255, 255, 255, 0.075);
    border-color: rgba(255, 255, 255, 0.15);
}

.wrapper.style2 input[type="checkbox"]:checked + label:before,
.wrapper.style2 input[type="radio"]:checked + label:before {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #474a4c;
}

.wrapper.style2 input[type="checkbox"]:focus + label:before,
.wrapper.style2 input[type="radio"]:focus + label:before {
    border-color: #ffffff;
    box-shadow: 0 0 0 1px #ffffff;
}

.wrapper.style2 ::-webkit-input-placeholder {
    color: #b8b6b8 !important;
}

.wrapper.style2 :-moz-placeholder {
    color: #b8b6b8 !important;
}

.wrapper.style2 ::-moz-placeholder {
    color: #b8b6b8 !important;
}

.wrapper.style2 :-ms-input-placeholder {
    color: #b8b6b8 !important;
}

.wrapper.style2 .formerize-placeholder {
    color: #b8b6b8 !important;
}

.wrapper.style2 form.alt input[type="text"],
.wrapper.style2 form.alt input[type="password"],
.wrapper.style2 form.alt input[type="email"],
.wrapper.style2 form.alt select,
.wrapper.style2 form.alt textarea {
    background-color: transparent;
}

.wrapper.style2 ul.alt > li {
    border-top-color: rgba(255, 255, 255, 0.15);
}

.wrapper.style2 ul.icons.major > li:first-child:before {
    background-color: rgba(255, 255, 255, 0.15);
}

.wrapper.style2 ul.icons.major > li > .icon {
    background-color: #474a4c;
    border-color: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.5);
}

.wrapper.style2 ul.icons.major > li > .icon.alt:before {
    color: #474a4c;
    text-shadow: 1px 0px 0px rgba(255, 255, 255, 0.5), -1px 0px 0px rgba(255, 255, 255, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.5), 0px -1px 0px rgba(255, 255, 255, 0.5);
}

.wrapper.style2 ul.icons.major > li.special > .icon {
    background-color: #ffffff;
    border-color: transparent;
    color: #474a4c;
}

.wrapper.style2 ul.icons.major > li.special > .icon.alt:before {
    color: #ffffff;
    text-shadow: 1px 0px 0px #474a4c, -1px 0px 0px #474a4c, 0px 1px 0px #474a4c, 0px -1px 0px #474a4c;
}

.wrapper.style2 ul.labeled-icons > li {
    border-left-color: rgba(255, 255, 255, 0.15);
}

.wrapper.style2 ul.labeled-icons > li > .icon:before {
    color: #ffffff;
}

.wrapper.style2 ul.checklist li:before {
    color: #d3d2d3;
}

.wrapper.style2 table tbody tr {
    border-color: rgba(255, 255, 255, 0.15);
}

.wrapper.style2 table tbody tr:nth-child(2n + 1) {
    background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style2 table th {
    color: #ffffff;
}

.wrapper.style2 table thead {
    border-bottom-color: rgba(255, 255, 255, 0.15);
}

.wrapper.style2 table tfoot {
    border-top-color: rgba(255, 255, 255, 0.15);
}

.wrapper.style2 table.alt tbody tr td {
    border-color: rgba(255, 255, 255, 0.15);
}

.wrapper.style2 input[type="submit"],
.wrapper.style2 input[type="reset"],
.wrapper.style2 input[type="button"],
.wrapper.style2 button,
.wrapper.style2 .button {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.15);
    color: #ffffff !important;
}

.wrapper.style2 input[type="submit"].icon:before,
.wrapper.style2 input[type="reset"].icon:before,
.wrapper.style2 input[type="button"].icon:before,
.wrapper.style2 button.icon:before,
.wrapper.style2 .button.icon:before {
    color: #b8b6b8;
}

.wrapper.style2 input[type="submit"]:hover,
.wrapper.style2 input[type="reset"]:hover,
.wrapper.style2 input[type="button"]:hover,
.wrapper.style2 button:hover,
.wrapper.style2 .button:hover {
    background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style2 input[type="submit"]:active,
.wrapper.style2 input[type="reset"]:active,
.wrapper.style2 input[type="button"]:active,
.wrapper.style2 button:active,
.wrapper.style2 .button:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.wrapper.style2 input[type="submit"].primary,
.wrapper.style2 input[type="reset"].primary,
.wrapper.style2 input[type="button"].primary,
.wrapper.style2 button.primary,
.wrapper.style2 .button.primary {
    background-color: #ffffff;
    box-shadow: none;
    color: #474a4c !important;
}

.wrapper.style2 input[type="submit"].primary:hover,
.wrapper.style2 input[type="reset"].primary:hover,
.wrapper.style2 input[type="button"].primary:hover,
.wrapper.style2 button.primary:hover,
.wrapper.style2 .button.primary:hover {
    background-color: white;
}

.wrapper.style2 input[type="submit"].primary:active,
.wrapper.style2 input[type="reset"].primary:active,
.wrapper.style2 input[type="button"].primary:active,
.wrapper.style2 button.primary:active,
.wrapper.style2 .button.primary:active {
    background-color: #f2f2f2;
}

.wrapper.style2 input[type="submit"].primary.icon:before,
.wrapper.style2 input[type="reset"].primary.icon:before,
.wrapper.style2 input[type="button"].primary.icon:before,
.wrapper.style2 button.primary.icon:before,
.wrapper.style2 .button.primary.icon:before {
    color: #474a4c;
}

.wrapper.style2 input[type="submit"].alt,
.wrapper.style2 input[type="reset"].alt,
.wrapper.style2 input[type="button"].alt,
.wrapper.style2 button.alt,
.wrapper.style2 .button.alt {
    background-color: #ffffff;
    box-shadow: none;
    color: #474a4c !important;
}

.wrapper.style2 input[type="submit"].alt:hover,
.wrapper.style2 input[type="reset"].alt:hover,
.wrapper.style2 input[type="button"].alt:hover,
.wrapper.style2 button.alt:hover,
.wrapper.style2 .button.alt:hover {
    background-color: white;
}

.wrapper.style2 input[type="submit"].alt:active,
.wrapper.style2 input[type="reset"].alt:active,
.wrapper.style2 input[type="button"].alt:active,
.wrapper.style2 button.alt:active,
.wrapper.style2 .button.alt:active {
    background-color: #f2f2f2;
}

.wrapper.style2 .icon.alt:before {
    color: #474a4c;
    text-shadow: 1px 0px 0px #ffffff, -1px 0px 0px #ffffff, 0px 1px 0px #ffffff, 0px -1px 0px #ffffff;
}

.wrapper.style3 {
    background-color: #eeefef;
    color: #555;
}

.wrapper.style3 input, .wrapper.style3 select, .wrapper.style3 textarea {
    color: #555;
}

.wrapper.style3 a {
    color: #222;
}

.wrapper.style3 strong, .wrapper.style3 b {
    color: #555;
}

.wrapper.style3 h1, .wrapper.style3 h2, .wrapper.style3 h3, .wrapper.style3 h4, .wrapper.style3 h5, .wrapper.style3 h6 {
    color: #555;
}

.wrapper.style3 blockquote {
    border-left-color: rgba(144, 144, 144, 0.25);
}

.wrapper.style3 code {
    background: rgba(144, 144, 144, 0.075);
    border-color: rgba(144, 144, 144, 0.25);
}

.wrapper.style3 hr {
    border-bottom-color: rgba(144, 144, 144, 0.25);
}

.wrapper.style3 label {
    color: #555;
}

.wrapper.style3 input[type="text"],
.wrapper.style3 input[type="password"],
.wrapper.style3 input[type="email"],
.wrapper.style3 select,
.wrapper.style3 textarea {
    background-color: rgba(144, 144, 144, 0.075);
    border-color: rgba(144, 144, 144, 0.25);
    color: inherit;
}

.wrapper.style3 input[type="text"]:focus,
.wrapper.style3 input[type="password"]:focus,
.wrapper.style3 input[type="email"]:focus,
.wrapper.style3 select:focus,
.wrapper.style3 textarea:focus {
    border-color: #222;
    box-shadow: 0 0 0 1px #222;
}

.wrapper.style3 select {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(144, 144, 144, 0.25)' /%3E%3C/svg%3E");
}

.wrapper.style3 select option {
    color: #555;
    background: #eeefef;
}

.wrapper.style3 input[type="checkbox"] + label,
.wrapper.style3 input[type="radio"] + label {
    color: #777;
}

.wrapper.style3 input[type="checkbox"] + label:before,
.wrapper.style3 input[type="radio"] + label:before {
    background-color: rgba(144, 144, 144, 0.075);
    border-color: rgba(144, 144, 144, 0.25);
}

.wrapper.style3 input[type="checkbox"]:checked + label:before,
.wrapper.style3 input[type="radio"]:checked + label:before {
    background-color: #222;
    border-color: #222;
    color: #eeefef;
}

.wrapper.style3 input[type="checkbox"]:focus + label:before,
.wrapper.style3 input[type="radio"]:focus + label:before {
    border-color: #222;
    box-shadow: 0 0 0 1px #222;
}

.wrapper.style3 ::-webkit-input-placeholder {
    color: #bbb !important;
}

.wrapper.style3 :-moz-placeholder {
    color: #bbb !important;
}

.wrapper.style3 ::-moz-placeholder {
    color: #bbb !important;
}

.wrapper.style3 :-ms-input-placeholder {
    color: #bbb !important;
}

.wrapper.style3 .formerize-placeholder {
    color: #bbb !important;
}

.wrapper.style3 form.alt input[type="text"],
.wrapper.style3 form.alt input[type="password"],
.wrapper.style3 form.alt input[type="email"],
.wrapper.style3 form.alt select,
.wrapper.style3 form.alt textarea {
    background-color: transparent;
}

.wrapper.style3 ul.alt > li {
    border-top-color: rgba(144, 144, 144, 0.25);
}

.wrapper.style3 ul.icons.major > li:first-child:before {
    background-color: rgba(144, 144, 144, 0.25);
}

.wrapper.style3 ul.icons.major > li > .icon {
    background-color: #eeefef;
    border-color: rgba(144, 144, 144, 0.25);
    color: rgba(144, 144, 144, 0.5);
}

.wrapper.style3 ul.icons.major > li > .icon.alt:before {
    color: #eeefef;
    text-shadow: 1px 0px 0px rgba(144, 144, 144, 0.5), -1px 0px 0px rgba(144, 144, 144, 0.5), 0px 1px 0px rgba(144, 144, 144, 0.5), 0px -1px 0px rgba(144, 144, 144, 0.5);
}

.wrapper.style3 ul.icons.major > li.special > .icon {
    background-color: #222;
    border-color: transparent;
    color: #eeefef;
}

.wrapper.style3 ul.icons.major > li.special > .icon.alt:before {
    color: #222;
    text-shadow: 1px 0px 0px #eeefef, -1px 0px 0px #eeefef, 0px 1px 0px #eeefef, 0px -1px 0px #eeefef;
}

.wrapper.style3 ul.labeled-icons > li {
    border-left-color: rgba(144, 144, 144, 0.25);
}

.wrapper.style3 ul.labeled-icons > li > .icon:before {
    color: #222;
}

.wrapper.style3 ul.checklist li:before {
    color: #777;
}

.wrapper.style3 table tbody tr {
    border-color: rgba(144, 144, 144, 0.25);
}

.wrapper.style3 table tbody tr:nth-child(2n + 1) {
    background-color: rgba(144, 144, 144, 0.075);
}

.wrapper.style3 table th {
    color: #555;
}

.wrapper.style3 table thead {
    border-bottom-color: rgba(144, 144, 144, 0.25);
}

.wrapper.style3 table tfoot {
    border-top-color: rgba(144, 144, 144, 0.25);
}

.wrapper.style3 table.alt tbody tr td {
    border-color: rgba(144, 144, 144, 0.25);
}

.wrapper.style3 input[type="submit"],
.wrapper.style3 input[type="reset"],
.wrapper.style3 input[type="button"],
.wrapper.style3 button,
.wrapper.style3 .button {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px rgba(144, 144, 144, 0.25);
    color: #555;
}

.wrapper.style3 input[type="submit"].icon:before,
.wrapper.style3 input[type="reset"].icon:before,
.wrapper.style3 input[type="button"].icon:before,
.wrapper.style3 button.icon:before,
.wrapper.style3 .button.icon:before {
    color: #bbb;
}

.wrapper.style3 input[type="submit"]:hover,
.wrapper.style3 input[type="reset"]:hover,
.wrapper.style3 input[type="button"]:hover,
.wrapper.style3 button:hover,
.wrapper.style3 .button:hover {
    background-color: rgba(144, 144, 144, 0.075);
}

.wrapper.style3 input[type="submit"]:active,
.wrapper.style3 input[type="reset"]:active,
.wrapper.style3 input[type="button"]:active,
.wrapper.style3 button:active,
.wrapper.style3 .button:active {
    background-color: rgba(144, 144, 144, 0.2);
}

.wrapper.style3 input[type="submit"].primary,
.wrapper.style3 input[type="reset"].primary,
.wrapper.style3 input[type="button"].primary,
.wrapper.style3 button.primary,
.wrapper.style3 .button.primary {
    background-color: #222;
    box-shadow: none;
    color: #eeefef !important;
}

.wrapper.style3 input[type="submit"].primary:hover,
.wrapper.style3 input[type="reset"].primary:hover,
.wrapper.style3 input[type="button"].primary:hover,
.wrapper.style3 button.primary:hover,
.wrapper.style3 .button.primary:hover {
    background-color: #2f2f2f;
}

.wrapper.style3 input[type="submit"].primary:active,
.wrapper.style3 input[type="reset"].primary:active,
.wrapper.style3 input[type="button"].primary:active,
.wrapper.style3 button.primary:active,
.wrapper.style3 .button.primary:active {
    background-color: #151515;
}

.wrapper.style3 input[type="submit"].primary.icon:before,
.wrapper.style3 input[type="reset"].primary.icon:before,
.wrapper.style3 input[type="button"].primary.icon:before,
.wrapper.style3 button.primary.icon:before,
.wrapper.style3 .button.primary.icon:before {
    color: #eeefef;
}

.wrapper.style3 input[type="submit"].alt,
.wrapper.style3 input[type="reset"].alt,
.wrapper.style3 input[type="button"].alt,
.wrapper.style3 button.alt,
.wrapper.style3 .button.alt {
    background-color: #555;
    box-shadow: none;
    color: #eeefef !important;
}

.wrapper.style3 input[type="submit"].alt:hover,
.wrapper.style3 input[type="reset"].alt:hover,
.wrapper.style3 input[type="button"].alt:hover,
.wrapper.style3 button.alt:hover,
.wrapper.style3 .button.alt:hover {
    background-color: #626262;
}

.wrapper.style3 input[type="submit"].alt:active,
.wrapper.style3 input[type="reset"].alt:active,
.wrapper.style3 input[type="button"].alt:active,
.wrapper.style3 button.alt:active,
.wrapper.style3 .button.alt:active {
    background-color: #484848;
}

.wrapper.style3 .icon.alt:before {
    color: #eeefef;
    text-shadow: 1px 0px 0px #555, -1px 0px 0px #555, 0px 1px 0px #555, 0px -1px 0px #555;
}

.wrapper.style4 {
    background-color: #324440;
    color: #9fc7bf;
}

.wrapper.style4 input, .wrapper.style4 select, .wrapper.style4 textarea {
    color: #9fc7bf;
}

.wrapper.style4 a {
    color: #ddcfe3;
}

.wrapper.style4 strong, .wrapper.style4 b {
    color: #9fc7bf;
}

.wrapper.style4 h1, .wrapper.style4 h2, .wrapper.style4 h3, .wrapper.style4 h4, .wrapper.style4 h5, .wrapper.style4 h6 {
    color: #9fc7bf;
}

.wrapper.style4 blockquote {
    border-left-color: #9fc7bf;
}

.wrapper.style4 code {
    background: rgba(255, 255, 255, 0.075);
    border-color: #9fc7bf;
}

.wrapper.style4 hr {
    border-bottom-color: #9fc7bf;
}

.wrapper.style4 label {
    color: #9fc7bf;
}

.wrapper.style4 input[type="text"],
.wrapper.style4 input[type="password"],
.wrapper.style4 input[type="email"],
.wrapper.style4 select,
.wrapper.style4 textarea {
    background-color: rgba(255, 255, 255, 0.075);
    border-color: #9fc7bf;
    color: inherit;
}

.wrapper.style4 input[type="text"]:focus,
.wrapper.style4 input[type="password"]:focus,
.wrapper.style4 input[type="email"]:focus,
.wrapper.style4 select:focus,
.wrapper.style4 textarea:focus {
    border-color: #ddcfe3;
    box-shadow: 0 0 0 1px #ddcfe3;
}

.wrapper.style4 select {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='%23ba9fc7' /%3E%3C/svg%3E");
}

.wrapper.style4 select option {
    color: #9fc7bf;
    background: #324440;
}

.wrapper.style4 input[type="checkbox"] + label,
.wrapper.style4 input[type="radio"] + label {
    color: #9fc7bf;
}

.wrapper.style4 input[type="checkbox"] + label:before,
.wrapper.style4 input[type="radio"] + label:before {
    background-color: rgba(255, 255, 255, 0.075);
    border-color: #9fc7bf;
}

.wrapper.style4 input[type="checkbox"]:checked + label:before,
.wrapper.style4 input[type="radio"]:checked + label:before {
    background-color: #ddcfe3;
    border-color: #ddcfe3;
    color: #324440;
}

.wrapper.style4 input[type="checkbox"]:focus + label:before,
.wrapper.style4 input[type="radio"]:focus + label:before {
    border-color: #ddcfe3;
    box-shadow: 0 0 0 1px #ddcfe3;
}

.wrapper.style4 ::-webkit-input-placeholder {
    color: #9fc7bf !important;
}

.wrapper.style4 :-moz-placeholder {
    color: #9fc7bf !important;
}

.wrapper.style4 ::-moz-placeholder {
    color: #9fc7bf !important;
}

.wrapper.style4 :-ms-input-placeholder {
    color: #9fc7bf !important;
}

.wrapper.style4 .formerize-placeholder {
    color: #9fc7bf !important;
}

.wrapper.style4 form.alt input[type="text"],
.wrapper.style4 form.alt input[type="password"],
.wrapper.style4 form.alt input[type="email"],
.wrapper.style4 form.alt select,
.wrapper.style4 form.alt textarea {
    background-color: transparent;
}

.wrapper.style4 ul.alt > li {
    border-top-color: #9fc7bf;
}

.wrapper.style4 ul.icons.major > li:first-child:before {
    background-color: #9fc7bf;
}

.wrapper.style4 ul.icons.major > li > .icon {
    background-color: #324440;
    border-color: #9fc7bf;
    color: #9fc7bf;
}

.wrapper.style4 ul.icons.major > li > .icon.alt:before {
    color: #324440;
    text-shadow: 1px 0px 0px #9fc7bf, -1px 0px 0px #9fc7bf, 0px 1px 0px #9fc7bf, 0px -1px 0px #9fc7bf;
}

.wrapper.style4 ul.icons.major > li.special > .icon {
    background-color: #ddcfe3;
    border-color: transparent;
    color: #324440;
}

.wrapper.style4 ul.icons.major > li.special > .icon.alt:before {
    color: #ddcfe3;
    text-shadow: 1px 0px 0px #324440, -1px 0px 0px #324440, 0px 1px 0px #324440, 0px -1px 0px #324440;
}

.wrapper.style4 ul.labeled-icons > li {
    border-left-color: #9fc7bf;
}

.wrapper.style4 ul.labeled-icons > li > .icon:before {
    color: #ddcfe3;
}

.wrapper.style4 ul.checklist li:before {
    color: #9fc7bf;
}

.wrapper.style4 table tbody tr {
    border-color: #9fc7bf;
}

.wrapper.style4 table tbody tr:nth-child(2n + 1) {
    background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style4 table th {
    color: #9fc7bf;
}

.wrapper.style4 table thead {
    border-bottom-color: #9fc7bf;
}

.wrapper.style4 table tfoot {
    border-top-color: #9fc7bf;
}

.wrapper.style4 table.alt tbody tr td {
    border-color: #9fc7bf;
}

.wrapper.style4 input[type="submit"],
.wrapper.style4 input[type="reset"],
.wrapper.style4 input[type="button"],
.wrapper.style4 button,
.wrapper.style4 .button {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #9fc7bf;
    color: #9fc7bf !important;
}

.wrapper.style4 input[type="submit"].icon:before,
.wrapper.style4 input[type="reset"].icon:before,
.wrapper.style4 input[type="button"].icon:before,
.wrapper.style4 button.icon:before,
.wrapper.style4 .button.icon:before {
    color: #9fc7bf;
}

.wrapper.style4 input[type="submit"]:hover,
.wrapper.style4 input[type="reset"]:hover,
.wrapper.style4 input[type="button"]:hover,
.wrapper.style4 button:hover,
.wrapper.style4 .button:hover {
    background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style4 input[type="submit"]:active,
.wrapper.style4 input[type="reset"]:active,
.wrapper.style4 input[type="button"]:active,
.wrapper.style4 button:active,
.wrapper.style4 .button:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.wrapper.style4 input[type="submit"].primary,
.wrapper.style4 input[type="reset"].primary,
.wrapper.style4 input[type="button"].primary,
.wrapper.style4 button.primary,
.wrapper.style4 .button.primary {
    background-color: #ddcfe3;
    box-shadow: none;
    color: #324440 !important;
}

.wrapper.style4 input[type="submit"].primary:hover,
.wrapper.style4 input[type="reset"].primary:hover,
.wrapper.style4 input[type="button"].primary:hover,
.wrapper.style4 button.primary:hover,
.wrapper.style4 .button.primary:hover {
    background-color: #e8dfed;
}

.wrapper.style4 input[type="submit"].primary:active,
.wrapper.style4 input[type="reset"].primary:active,
.wrapper.style4 input[type="button"].primary:active,
.wrapper.style4 button.primary:active,
.wrapper.style4 .button.primary:active {
    background-color: #d1bfda;
}

.wrapper.style4 input[type="submit"].primary.icon:before,
.wrapper.style4 input[type="reset"].primary.icon:before,
.wrapper.style4 input[type="button"].primary.icon:before,
.wrapper.style4 button.primary.icon:before,
.wrapper.style4 .button.primary.icon:before {
    color: #324440;
}

.wrapper.style4 input[type="submit"].alt,
.wrapper.style4 input[type="reset"].alt,
.wrapper.style4 input[type="button"].alt,
.wrapper.style4 button.alt,
.wrapper.style4 .button.alt {
    background-color: #9fc7bf;
    box-shadow: none;
    color: #324440 !important;
}

.wrapper.style4 input[type="submit"].alt:hover,
.wrapper.style4 input[type="reset"].alt:hover,
.wrapper.style4 input[type="button"].alt:hover,
.wrapper.style4 button.alt:hover,
.wrapper.style4 .button.alt:hover {
    background-color: #c6afd0;
}

.wrapper.style4 input[type="submit"].alt:active,
.wrapper.style4 input[type="reset"].alt:active,
.wrapper.style4 input[type="button"].alt:active,
.wrapper.style4 button.alt:active,
.wrapper.style4 .button.alt:active {
    background-color: #ae8fbe;
}

.wrapper.style4 .icon.alt:before {
    color: #324440;
    text-shadow: 1px 0px 0px #9fc7bf, -1px 0px 0px #9fc7bf, 0px 1px 0px #9fc7bf, 0px -1px 0px #9fc7bf;
}

.wrapper.style5 {
    background-color: #52a897;
    color: #ffffff;
}

.wrapper.style5 input, .wrapper.style5 select, .wrapper.style5 textarea {
    color: #ffffff;
}

.wrapper.style5 a {
    color: #ffffff;
}

.wrapper.style5 strong, .wrapper.style5 b {
    color: #ffffff;
}

.wrapper.style5 h1, .wrapper.style5 h2, .wrapper.style5 h3, .wrapper.style5 h4, .wrapper.style5 h5, .wrapper.style5 h6 {
    color: #ffffff;
}

.wrapper.style5 blockquote {
    border-left-color: #fff;
}

.wrapper.style5 code {
    background: rgba(255, 255, 255, 0.075);
    border-color: #fff;
}

.wrapper.style5 hr {
    border-bottom-color: #fff;
}

.wrapper.style5 label {
    color: #ffffff;
}

.wrapper.style5 input[type="text"],
.wrapper.style5 input[type="password"],
.wrapper.style5 input[type="email"],
.wrapper.style5 select,
.wrapper.style5 textarea {
    background-color: rgba(255, 255, 255, 0.075);
    border-color: #fff;
    color: inherit;
}

.wrapper.style5 input[type="text"]:focus,
.wrapper.style5 input[type="password"]:focus,
.wrapper.style5 input[type="email"]:focus,
.wrapper.style5 select:focus,
.wrapper.style5 textarea:focus {
    border-color: #ffffff;
    box-shadow: 0 0 0 1px #ffffff;
}

.wrapper.style5 select {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='%23fff' /%3E%3C/svg%3E");
}

.wrapper.style5 select option {
    color: #ffffff;
    background: #52a897;
}

.wrapper.style5 input[type="checkbox"] + label,
.wrapper.style5 input[type="radio"] + label {
    color: #e9e1eb;
}

.wrapper.style5 input[type="checkbox"] + label:before,
.wrapper.style5 input[type="radio"] + label:before {
    background-color: rgba(255, 255, 255, 0.075);
    border-color: #fff;
}

.wrapper.style5 input[type="checkbox"]:checked + label:before,
.wrapper.style5 input[type="radio"]:checked + label:before {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #52a897;
}

.wrapper.style5 input[type="checkbox"]:focus + label:before,
.wrapper.style5 input[type="radio"]:focus + label:before {
    border-color: #ffffff;
    box-shadow: 0 0 0 1px #ffffff;
}

.wrapper.style5 ::-webkit-input-placeholder {
    color: #dbcedf !important;
}

.wrapper.style5 :-moz-placeholder {
    color: #dbcedf !important;
}

.wrapper.style5 ::-moz-placeholder {
    color: #dbcedf !important;
}

.wrapper.style5 :-ms-input-placeholder {
    color: #dbcedf !important;
}

.wrapper.style5 .formerize-placeholder {
    color: #dbcedf !important;
}

.wrapper.style5 form.alt input[type="text"],
.wrapper.style5 form.alt input[type="password"],
.wrapper.style5 form.alt input[type="email"],
.wrapper.style5 form.alt select,
.wrapper.style5 form.alt textarea {
    background-color: transparent;
}

.wrapper.style5 ul.alt > li {
    border-top-color: #fff;
}

.wrapper.style5 ul.icons.major > li:first-child:before {
    background-color: #fff;
}

.wrapper.style5 ul.icons.major > li > .icon {
    background-color: #52a897;
    border-color: #fff;
    color: #fff;
}

.wrapper.style5 ul.icons.major > li > .icon.alt:before {
    color: #52a897;
    text-shadow: 1px 0px 0px #fff, -1px 0px 0px #fff, 0px 1px 0px #fff, 0px -1px 0px #fff;
}

.wrapper.style5 ul.icons.major > li.special > .icon {
    background-color: #ffffff;
    border-color: transparent;
    color: #52a897;
}

.wrapper.style5 ul.icons.major > li.special > .icon.alt:before {
    color: #ffffff;
    text-shadow: 1px 0px 0px #52a897, -1px 0px 0px #52a897, 0px 1px 0px #52a897, 0px -1px 0px #52a897;
}

.wrapper.style5 ul.labeled-icons > li {
    border-left-color: #fff;
}

.wrapper.style5 ul.labeled-icons > li > .icon:before {
    color: #ffffff;
}

.wrapper.style5 ul.checklist li:before {
    color: #e9e1eb;
}

.wrapper.style5 table tbody tr {
    border-color: #fff;
}

.wrapper.style5 table tbody tr:nth-child(2n + 1) {
    background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style5 table th {
    color: #ffffff;
}

.wrapper.style5 table thead {
    border-bottom-color: #fff;
}

.wrapper.style5 table tfoot {
    border-top-color: #fff;
}

.wrapper.style5 table.alt tbody tr td {
    border-color: #fff;
}

.wrapper.style5 input[type="submit"],
.wrapper.style5 input[type="reset"],
.wrapper.style5 input[type="button"],
.wrapper.style5 button,
.wrapper.style5 .button {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #fff;
    color: #ffffff !important;
}

.wrapper.style5 input[type="submit"].icon:before,
.wrapper.style5 input[type="reset"].icon:before,
.wrapper.style5 input[type="button"].icon:before,
.wrapper.style5 button.icon:before,
.wrapper.style5 .button.icon:before {
    color: #dbcedf;
}

.wrapper.style5 input[type="submit"]:hover,
.wrapper.style5 input[type="reset"]:hover,
.wrapper.style5 input[type="button"]:hover,
.wrapper.style5 button:hover,
.wrapper.style5 .button:hover {
    background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style5 input[type="submit"]:active,
.wrapper.style5 input[type="reset"]:active,
.wrapper.style5 input[type="button"]:active,
.wrapper.style5 button:active,
.wrapper.style5 .button:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.wrapper.style5 input[type="submit"].primary,
.wrapper.style5 input[type="reset"].primary,
.wrapper.style5 input[type="button"].primary,
.wrapper.style5 button.primary,
.wrapper.style5 .button.primary {
    background-color: #ffffff;
    box-shadow: none;
    color: #52a897 !important;
}

.wrapper.style5 input[type="submit"].primary:hover,
.wrapper.style5 input[type="reset"].primary:hover,
.wrapper.style5 input[type="button"].primary:hover,
.wrapper.style5 button.primary:hover,
.wrapper.style5 .button.primary:hover {
    background-color: white;
}

.wrapper.style5 input[type="submit"].primary:active,
.wrapper.style5 input[type="reset"].primary:active,
.wrapper.style5 input[type="button"].primary:active,
.wrapper.style5 button.primary:active,
.wrapper.style5 .button.primary:active {
    background-color: #f2f2f2;
}

.wrapper.style5 input[type="submit"].primary.icon:before,
.wrapper.style5 input[type="reset"].primary.icon:before,
.wrapper.style5 input[type="button"].primary.icon:before,
.wrapper.style5 button.primary.icon:before,
.wrapper.style5 .button.primary.icon:before {
    color: #52a897;
}

.wrapper.style5 input[type="submit"].alt,
.wrapper.style5 input[type="reset"].alt,
.wrapper.style5 input[type="button"].alt,
.wrapper.style5 button.alt,
.wrapper.style5 .button.alt {
    background-color: #ffffff;
    box-shadow: none;
    color: #52a897 !important;
}

.wrapper.style5 input[type="submit"].alt:hover,
.wrapper.style5 input[type="reset"].alt:hover,
.wrapper.style5 input[type="button"].alt:hover,
.wrapper.style5 button.alt:hover,
.wrapper.style5 .button.alt:hover {
    background-color: white;
}

.wrapper.style5 input[type="submit"].alt:active,
.wrapper.style5 input[type="reset"].alt:active,
.wrapper.style5 input[type="button"].alt:active,
.wrapper.style5 button.alt:active,
.wrapper.style5 .button.alt:active {
    background-color: #f2f2f2;
}

.wrapper.style5 .icon.alt:before {
    color: #52a897;
    text-shadow: 1px 0px 0px #ffffff, -1px 0px 0px #ffffff, 0px 1px 0px #ffffff, 0px -1px 0px #ffffff;
}

@media screen and (max-width: 980px) {

    .wrapper {
        padding: 5em 3em 3em 3em ;
    }

    .wrapper > .inner {
        width: 100%;
    }

}

@media screen and (max-width: 736px) {

    .wrapper {
        padding: 3em 2em 1em 2em ;
    }

}

@media screen and (max-width: 480px) {

    .wrapper {
        padding: 2em 2em 0.1em 2em ;
    }

}

@media screen and (max-width: 360px) {

    .wrapper {
        padding: 2em 1.25em 0.1em 1.25em ;
    }

}

/* Features */

.features {
    -moz-align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    font-size: 1.1em;
}

.features ul {
    list-style-type: none;
    padding: 0;
    flex: auto;
    -ms-flex: 1 1 auto;
}

.features ul.right {
    text-align: right;
}

.features ul.right .icon:before {
    float: right;
}

.features li {
    margin-bottom: 6em;
    padding-left: 0;
}

.features li:last-child {
    margin-bottom: 0;
}

.features .icon:before {
    float: left;
    font-size: 2.25em;
    line-height: inherit;
    margin-top: 0.4em;
    width: 1.5em;
}

.features object {
    margin: 0 0 2em 0;
    padding: 0 5%;
    max-height: 560px;
    width: 50%;
    -ms-flex: 1 1 auto;
}

@media screen and (max-width: 980px) {

    .features {
        -moz-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .features object {
        max-height: 30em;
        -moz-order: -1;
        -webkit-order: -1;
        -ms-order: -1;
        order: -1;
        height: auto;
        padding: 0;
        width: 30em;
        max-width: 75%;
        margin: 0 auto 3em auto;
    }

    .features ul {
        margin-top: 2em;
        text-align: center !important;
        width: 45%;
        padding: 0 7.5%;
    }

    .features ul .icon:before, .features ul.right .icon:before {
        display: block;
        float: none;
        margin: 0 auto 0.5em;
    }

    .features li {
        margin-bottom: 4em;
    }

}

@media screen and (max-width: 736px) {

    .features {
        font-size: 1em;
    }

    .features object {
        max-height: 20em;
    }

    .features ul {
        width: 100%;
        padding: 0 15%;
    }

}

@media screen and (max-width: 480px) {

    .features ul {
        margin: 0 0 2em;
        width: 100%;
    }

    .features li {
        margin-bottom: 2em;
    }

}

/* Spotlight */

.spotlight {
    -moz-align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
}

.spotlight .image, .spotlight article, .spotlight object {
    width: 45%;
}

.spotlight object {
    margin-bottom: 2em;
}

.spotlight.left {
    -moz-flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

.spotlight.right {
    -moz-flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

@media screen and (max-width: 980px) {

    .spotlight {
        -moz-flex-direction: row;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -moz-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
    }

    .spotlight .image, .spotlight object {
        width: 80%;
    }

    .spotlight ul.actions {
        -moz-justify-content: center;
        -webkit-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        width: 100%;
        margin-left: 0;
    }

    .spotlight ul.actions li:first-child {
        padding-left: 0;
    }

    .spotlight article {
        text-align: center;
        width: 100%;
    }

    .spotlight article ul.checklist {
        display: inline-block;
        text-align: left;
    }

}

@media screen and (max-width: 736px) {

    .spotlight .image {
        width: auto;
    }

}

@media screen and (max-width: 480px) {

    .spotlight object {
        width: 100%;
    }

}

/* Header */

body {
    padding-top: 3.5em;
}

body.landing {
    padding-top: 0;
}

@media screen and (max-width: 980px) {

    body {
        padding-top: 44px;
    }

}

#header {
    -moz-transition: background-color 0.6s ease-in-out;
    -webkit-transition: background-color 0.6s ease-in-out;
    -ms-transition: background-color 0.6s ease-in-out;
    transition: background-color 0.6s ease-in-out;
    background-color: #2e2b2e;
    background-color: rgba(46, 43, 46, 0.975);
    color: #d3d2d3;
    cursor: default;
    font-weight: 400;
    height: 3.5em;
    left: 0;
    line-height: 3.65em;
    position: fixed;
    text-align: right;
    text-transform: uppercase;
    top: 0;
    width: 100%;
    z-index: 10001;
}

#header > h1 {
    -moz-transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
    color: #ffffff;
    display: inline-block;
    font-weight: 600;
    height: inherit;
    left: 0;
    line-height: inherit;
    margin: 0 0 0 1.5em;
    padding: 0;
    position: absolute;
    top: 0;
}

#header > h1 a {
    padding: 0 !important;
    font-weight: inherit;
}

#header > h1 span {
    font-weight: 400;
}

#header a {
    -moz-transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
    color: inherit;
    display: inline-block;
    text-decoration: none;
    font-weight: 400;
    letter-spacing: 0.1em;
}

#header a:hover {
    color: #ffffff;
}

#header a[href="#nav"] {
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    padding: 0 0.75em 0;
}

#header a[href="#nav"]:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    text-transform: none !important;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

#header a[href="#nav"]:before {
    content: '\f0c9';
    line-height: inherit;
    margin: 0 0.5em 0 0;
}

#header a:last-child {
    padding-right: 1.5em;
}

#header.alt {
    position: fixed;
    background-color: transparent;
    color: #9fc7bf;
}

#header.alt > h1 {
    color: inherit;
}

@media screen and (max-width: 980px) {

    #header a[href="#nav"] > span {
        display: none;
    }

    #header a[href="#nav"]:before {
        margin: 0;
    }

    #header.alt {
        position: absolute;
    }

}

@media screen and (max-width: 736px) {

    #header {
        height: 3em;
        line-height: 3.125em;
    }

    #header > h1 {
        margin-left: 1em;
    }

    #header > h1 a {
        font-size: 0.8em;
    }

    #header a[href="#nav"] {
        padding: 0 1em !important;
    }

}

@media screen and (max-width: 480px) {

    #header {
        min-width: 320px;
    }

}

/* Nav */

#nav {
    -moz-transform: translateX(20em);
    -webkit-transform: translateX(20em);
    -ms-transform: translateX(20em);
    transform: translateX(20em);
    -moz-transition: -moz-transform 0.5s ease, visibility 0.5s;
    -webkit-transition: -webkit-transform 0.5s ease, visibility 0.5s;
    -ms-transition: -ms-transform 0.5s ease, visibility 0.5s;
    transition: transform 0.5s ease, visibility 0.5s;
    background-color: #52a897;
    color: #ffffff;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    max-width: 80%;
    overflow-y: auto;
    padding: 3em 2em;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    width: 20em;
    z-index: 10002;
}

#nav input, #nav select, #nav textarea {
    color: #ffffff;
}

#nav a {
    color: #ffffff;
}

#nav strong, #nav b {
    color: #ffffff;
}

#nav h1, #nav h2, #nav h3, #nav h4, #nav h5, #nav h6 {
    color: #ffffff;
}

#nav blockquote {
    border-left-color: #fff;
}

#nav code {
    background: rgba(255, 255, 255, 0.075);
    border-color: #fff;
}

#nav hr {
    border-bottom-color: #fff;
}

#nav input[type="submit"],
#nav input[type="reset"],
#nav input[type="button"],
#nav button,
#nav .button {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #fff;
    color: #ffffff !important;
}

#nav input[type="submit"].icon:before,
#nav input[type="reset"].icon:before,
#nav input[type="button"].icon:before,
#nav button.icon:before,
#nav .button.icon:before {
    color: #dbcedf;
}

#nav input[type="submit"]:hover,
#nav input[type="reset"]:hover,
#nav input[type="button"]:hover,
#nav button:hover,
#nav .button:hover {
    background-color: rgba(255, 255, 255, 0.075);
}

#nav input[type="submit"]:active,
#nav input[type="reset"]:active,
#nav input[type="button"]:active,
#nav button:active,
#nav .button:active {
    background-color: rgba(255, 255, 255, 0.2);
}

#nav input[type="submit"].primary,
#nav input[type="reset"].primary,
#nav input[type="button"].primary,
#nav button.primary,
#nav .button.primary {
    background-color: #ffffff;
    box-shadow: none;
    color: #52a897 !important;
}

#nav input[type="submit"].primary:hover,
#nav input[type="reset"].primary:hover,
#nav input[type="button"].primary:hover,
#nav button.primary:hover,
#nav .button.primary:hover {
    background-color: white;
}

#nav input[type="submit"].primary:active,
#nav input[type="reset"].primary:active,
#nav input[type="button"].primary:active,
#nav button.primary:active,
#nav .button.primary:active {
    background-color: #f2f2f2;
}

#nav input[type="submit"].primary.icon:before,
#nav input[type="reset"].primary.icon:before,
#nav input[type="button"].primary.icon:before,
#nav button.primary.icon:before,
#nav .button.primary.icon:before {
    color: #52a897;
}

#nav input[type="submit"].alt,
#nav input[type="reset"].alt,
#nav input[type="button"].alt,
#nav button.alt,
#nav .button.alt {
    background-color: #ffffff;
    box-shadow: none;
    color: #52a897 !important;
}

#nav input[type="submit"].alt:hover,
#nav input[type="reset"].alt:hover,
#nav input[type="button"].alt:hover,
#nav button.alt:hover,
#nav .button.alt:hover {
    background-color: white;
}

#nav input[type="submit"].alt:active,
#nav input[type="reset"].alt:active,
#nav input[type="button"].alt:active,
#nav button.alt:active,
#nav .button.alt:active {
    background-color: #f2f2f2;
}

#nav > ul {
    margin: 0 0 1em 0;
}

#nav > ul.links {
    list-style: none;
    padding: 0;
}

#nav > ul.links > li {
    padding: 0;
}

#nav > ul.links > li > a:not(.button) {
    border: 0;
    border-top: solid 1px rgba(255, 255, 255, 0.2);
    color: inherit;
    display: block;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    line-height: 3.5em;
    text-decoration: none;
}

@media screen and (max-width: 736px) {

    #nav > ul.links > li > a:not(.button) {
        font-size: 0.8em;
        line-height: 4em;
    }

}

#nav > ul.links > li > .button {
    display: block;
    margin: 0.5em 0 0 0;
}

#nav > ul.links > li:first-child > a:not(.button) {
    border-top: 0 !important;
}

#nav .close {
    text-decoration: none;
    -moz-transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border: 0;
    color: #e9e1eb;
    cursor: pointer;
    display: block;
    height: 3.5em;
    line-height: 3.65em;
    padding-right: 1.5em;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    vertical-align: middle;
    width: 7em;
}

#nav .close:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    text-transform: none !important;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

#nav .close:before {
    content: '\f00d';
    font-size: 1.25em;
}

#nav .close:hover {
    color: #ffffff;
}

@media screen and (max-width: 736px) {

    #nav .close {
        height: 4em;
        line-height: 4em;
        font-size: 0.8em;
    }

}

@media screen and (max-width: 736px) {

    #nav {
        padding: 2.5em 1.75em;
    }

}

#page-wrapper {
    -moz-transition: opacity 0.5s ease;
    -webkit-transition: opacity 0.5s ease;
    -ms-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
}

body.is-nav-visible #nav {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    visibility: visible;
}

body.is-nav-visible #page-wrapper {
    opacity: 0.25;
}

/* Banner */

#banner {
    background-color: #324440;
    color: #9fc7bf;
    background-image: -moz-linear-gradient(top, rgba(160,167,232,0.25), rgba(86,153,158,0.35)), -moz-linear-gradient(top, rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url("../../images/banner.jpg");
    background-image: -webkit-linear-gradient(top, rgba(160,167,232,0.25), rgba(86,153,158,0.35)), -webkit-linear-gradient(top, rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url("../../images/banner.jpg");
    background-image: -ms-linear-gradient(top, rgba(160,167,232,0.25), rgba(86,153,158,0.35)), -ms-linear-gradient(top, rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url("../../images/banner.jpg");
    background-image: linear-gradient(top, rgba(160,167,232,0.25), rgba(86,153,158,0.35)), linear-gradient(top, rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url("../../images/banner.jpg");
    background-attachment: fixed, fixed, fixed;
    background-position: center 100%, center 100%, center 100%;
    background-repeat: repeat, repeat, no-repeat;
    background-size: cover, cover, cover;
    height: 100vh;
    min-height: 40em;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: uppercase;
}

#banner input[type="submit"],
#banner input[type="reset"],
#banner input[type="button"],
#banner button,
#banner .button {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #9fc7bf;
    color: #9fc7bf !important;
}

#banner input[type="submit"].icon:before,
#banner input[type="reset"].icon:before,
#banner input[type="button"].icon:before,
#banner button.icon:before,
#banner .button.icon:before {
    color: #9fc7bf;
}

#banner input[type="submit"]:hover,
#banner input[type="reset"]:hover,
#banner input[type="button"]:hover,
#banner button:hover,
#banner .button:hover {
    background-color: rgba(255, 255, 255, 0.075);
}

#banner input[type="submit"]:active,
#banner input[type="reset"]:active,
#banner input[type="button"]:active,
#banner button:active,
#banner .button:active {
    background-color: rgba(255, 255, 255, 0.2);
}

#banner input[type="submit"].primary,
#banner input[type="reset"].primary,
#banner input[type="button"].primary,
#banner button.primary,
#banner .button.primary {
    background-color: #ddcfe3;
    box-shadow: none;
    color: #324440 !important;
}

#banner input[type="submit"].primary:hover,
#banner input[type="reset"].primary:hover,
#banner input[type="button"].primary:hover,
#banner button.primary:hover,
#banner .button.primary:hover {
    background-color: #e8dfed;
}

#banner input[type="submit"].primary:active,
#banner input[type="reset"].primary:active,
#banner input[type="button"].primary:active,
#banner button.primary:active,
#banner .button.primary:active {
    background-color: #d1bfda;
}

#banner input[type="submit"].primary.icon:before,
#banner input[type="reset"].primary.icon:before,
#banner input[type="button"].primary.icon:before,
#banner button.primary.icon:before,
#banner .button.primary.icon:before {
    color: #324440;
}

#banner input[type="submit"].alt,
#banner input[type="reset"].alt,
#banner input[type="button"].alt,
#banner button.alt,
#banner .button.alt {
    background-color: #9fc7bf;
    box-shadow: none;
    color: #324440 !important;
}

#banner input[type="submit"].alt:hover,
#banner input[type="reset"].alt:hover,
#banner input[type="button"].alt:hover,
#banner button.alt:hover,
#banner .button.alt:hover {
    background-color: #c6afd0;
}

#banner input[type="submit"].alt:active,
#banner input[type="reset"].alt:active,
#banner input[type="button"].alt:active,
#banner button.alt:active,
#banner .button.alt:active {
    background-color: #ae8fbe;
}

#banner input, #banner select, #banner textarea {
    color: #9fc7bf;
}

#banner a {
    color: #ddcfe3;
}

#banner strong, #banner b {
    color: #9fc7bf;
}

#banner h1, #banner h2, #banner h3, #banner h4, #banner h5, #banner h6 {
    color: #9fc7bf;
}

#banner blockquote {
    border-left-color: #9fc7bf;
}

#banner code {
    background: rgba(255, 255, 255, 0.075);
    border-color: #9fc7bf;
}

#banner hr {
    border-bottom-color: #9fc7bf;
}

#banner:after {
    -moz-transition: opacity 1.5s ease;
    -webkit-transition: opacity 1.5s ease;
    -ms-transition: opacity 1.5s ease;
    transition: opacity 1.5s ease;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #324440;
    opacity: 0;
}

#banner .inner {
    padding: 8em 4em 6em 4em ;
    -moz-transition: opacity 0.85s ease, -moz-filter 0.85s ease, -moz-transform 0.85s ease;
    -webkit-transition: opacity 0.85s ease, -webkit-filter 0.85s ease, -webkit-transform 0.85s ease;
    -ms-transition: opacity 0.85s ease, -ms-filter 0.85s ease, -ms-transform 0.85s ease;
    transition: opacity 0.85s ease, filter 0.85s ease, transform 0.85s ease;
    -moz-transition-delay: 0.75s;
    -webkit-transition-delay: 0.75s;
    -ms-transition-delay: 0.75s;
    transition-delay: 0.75s;
    bottom: 0;
    cursor: default;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
}

#banner h2 {
    font-size: 2.5em;
    line-height: 1.5em;
}

@media screen and (max-width: 1680px) {

    #banner .inner {
        padding: 5em 2em 3em 2em ;
    }

}

@media screen and (max-width: 980px) {

    #banner .inner {
        padding: 4em 2em 2em 2em ;
    }

    #banner h2 {
        font-size: 2em;
    }

    #banner h2 br {
        display: none;
    }

}

@media screen and (max-width: 736px) {

    #banner {
        height: 75vh;
        min-height: 25em;
    }

    #banner .inner {
        padding: 2.5em 2.5em 0.5em 2.5em ;
    }

    #banner h2 {
        font-size: 1.5em;
    }

}

@media screen and (max-width: 360px) {

    #banner .inner {
        padding: 2em 2em 0.1em 2em ;
    }

}

body.is-mobile #banner {
    background-attachment: scroll;
}

body.is-preload #banner:after {
    opacity: 1;
}

body.is-preload #banner .inner {
    opacity: 0;
    -moz-filter: blur(2px);
    -webkit-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}

/* Main */

#main > header {
    padding: 10em 0 8em 0 ;
    background-image: -moz-linear-gradient(top, rgba(160,167,232,0.25), rgba(86,153,158,0.35)), -moz-linear-gradient(top, rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url("../../images/banner.jpg");
    background-image: -webkit-linear-gradient(top, rgba(160,167,232,0.25), rgba(86,153,158,0.35)), -webkit-linear-gradient(top, rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url("../../images/banner.jpg");
    background-image: -ms-linear-gradient(top, rgba(160,167,232,0.25), rgba(86,153,158,0.35)), -ms-linear-gradient(top, rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url("../../images/banner.jpg");
    background-image: linear-gradient(top, rgba(160,167,232,0.25), rgba(86,153,158,0.35)), linear-gradient(top, rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url("../../images/banner.jpg");
    background-attachment: fixed, fixed, fixed;
    background-position: center 100%, center 100%, center 100%;
    background-repeat: repeat, repeat, no-repeat;
    background-size: cover, cover, cover;
    color: #ffffff;
    text-align: center;
}

#main > header h1, #main > header h2, #main > header h3, #main > header h4, #main > header h5 {
    color: #ffffff;
}

@media screen and (max-width: 1280px) {

    #main > header {
        padding: 8em 3em 6em 3em ;
    }

}

@media screen and (max-width: 980px) {

    #main > header {
        padding: 10em 3em 8em 3em ;
    }

}

@media screen and (max-width: 736px) {

    #main > header {
        padding: 5em 3em 3em 3em ;
    }

    #main > header h2 {
        font-size: 1.25em;
        margin: 0 0 1em 0;
    }

}

body.is-mobile #main > header {
    background-attachment: scroll;
}

/* Footer */

#footer {
    background-color: #eeefef;
    color: #555;
    padding: 4em 0;
}

#footer input, #footer select, #footer textarea {
    color: #555;
}

#footer a {
    color: #222;
}

#footer strong, #footer b {
    color: #555;
}

#footer h1, #footer h2, #footer h3, #footer h4, #footer h5, #footer h6 {
    color: #555;
}

#footer blockquote {
    border-left-color: rgba(144, 144, 144, 0.25);
}

#footer code {
    background: rgba(144, 144, 144, 0.075);
    border-color: rgba(144, 144, 144, 0.25);
}

#footer hr {
    border-bottom-color: rgba(144, 144, 144, 0.25);
}

#footer .copyright {
    color: #bbb;
    margin: 0 0 2em 0;
    padding: 0;
    text-align: center;
}

#footer .copyright li {
    border-left: solid 1px rgba(144, 144, 144, 0.25);
    display: inline-block;
    list-style: none;
    margin-left: 1.5em;
    padding-left: 1.5em;
}

#footer .copyright li:first-child {
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
}

@media screen and (max-width: 736px) {
    #footer {
        padding: 3em 0 1em 0 ;
    }

    #footer .copyright li {
        display: block;
        border-left: 0;
        margin-left: 0;
        padding-left: 0;
    }
}
